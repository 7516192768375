sap.ui.define([
    "univar/tcard/controller/BaseController",
    "sap/m/MessageBox",
    "sap/ui/model/json/JSONModel",
	"univar/tcard/src/api-comm/password-policy",
	'sap/m/MessageToast',
	"univar/tcard/src/utilities/busyStack"
], function(BaseController, MessageBox, JSONModel, PasswordPolicyAPI, Toast) {
	"use strict";

    var policy = {}

	return BaseController.extend("univar.tcard.controller.Admin.PasswordPolicy", {

		onInit: function() {
			this._oninit()
			var oRouter = this.getRouter();
			oRouter.getRoute("passwordpolicy").attachMatched(this._onRouteMatched, this);
		},
		
		_onRouteMatched : function (oEvent) {
			
			this.isLoggedIn().then((result)=>{
				if(result){
					this.onRouteMatched(oEvent)
				}else{
                    this.navigate('login')
                }
            }).catch(err=>{
                console.log(err)
                this.navigate('login')
            })
        },
        onRouteMatched:function(oEvent){
            this.loadPasswordPolicy()
        },
        loadPasswordPolicy: function(){
            //this.getView().setBusy(true)
            var setNotBusy=this.getBusyStack().addBusy(view)
            PasswordPolicyAPI.get().then((result)=>{
                if(result){
                    policy = {
                        MinLengthWeak:result.MinLengthWeak,
                        MinLengthStrong:result.MinLengthStrong,
                        MinLowerCase:result.MinLowerCase,
                        MinLowerCaseEnforced:result.MinLowerCaseEnforced,
                        MinUpperCase:result.MinUpperCase,
                        MinUpperCaseEnforced:result.MinUpperCaseEnforced,
                        MinNumeric:result.MinNumeric,
                        MinNumericEnforced:result.MinNumericEnforced,
                        MinSpecial:result.MinSpecial,
                        MinSpecialEnforced:result.MinSpecialEnforced,
                        Special:result.Special
                      }
                    result.dirty = false
                    var jModel = new JSONModel(result);
                    jModel.attachPropertyChange(this.onChange)
                    this.getView().setModel(jModel)
                    //this.getView().setBusy(false)
                    setNotBusy()
                }
            }).catch((err)=>{
                console.log(err)
                //this.getView().setBusy(false)
                setNotBusy()
                Toast.show("Failed to load policy")
                $( ".sapMMessageToast" ).addClass( "sapMMessageToastDanger " );
            })
        },
        onChange:function(oEvent){

            var source = oEvent.getSource();
            var newPolicy = source.oData;
            var dirty = false
            for(var prop in policy){
                if(policy.hasOwnProperty(prop) && newPolicy.hasOwnProperty(prop) && policy[prop] != newPolicy[prop]){
                    dirty = true;
                }
            }
            if(newPolicy.dirty !== dirty){
                source.setProperty('/dirty',dirty)
            }
            if(!newPolicy.Special && newPolicy.MinSpecial) {
                newPolicy.MinSpecial = 0
                source.setProperty('/MinSpecial',minPossible)

            }

            var minPossible = 0
            minPossible +=   parseInt( newPolicy.MinLowerCase )
            minPossible +=   parseInt( newPolicy.MinUpperCase )
            minPossible +=   parseInt( newPolicy.MinNumeric )
            minPossible +=   parseInt( newPolicy.MinSpecial )

            if(minPossible>newPolicy.MinLengthStrong){
                newPolicy.MinLengthStrong = minPossible
                source.setProperty('/MinLengthStrong',minPossible)
            }


        },
        onSavePress:function(oEvent){
            var newPolicy = this.getView().getModel().oData;
            //this.getView().setBusy(true)
            var view=this.getView()
            setNotBusy=this.getBusyStack().addBusy(view)
            PasswordPolicyAPI.post(newPolicy).then(result=>{
                if(result){
                    this.getView().getModel().setProperty('/dirty',false)
                    this._reloadPP()
                    Toast.show("Policy Saved")
                    $( ".sapMMessageToast" ).addClass( "sapMMessageToastSuccess " );
                }else{
                    Toast.show("Failed to save policy")
                    $( ".sapMMessageToast" ).addClass( "sapMMessageToastDanger " );
                }
            }).catch(err=> {
                console.log(err)
                Toast.show("Failed to save policy")
                $( ".sapMMessageToast" ).addClass( "sapMMessageToastDanger " );
            }).finally(()=>{
                this.loadPasswordPolicy()
            })
        },
        onCancelPress:function(oEvent){
            
            var result = policy
            result.dirty = false
            var jModel = new JSONModel(result)
            jModel.attachPropertyChange(this.onChange)
            this.getView().setModel(jModel)
        }
	});
}, /* bExport= */ true);
