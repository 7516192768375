sap.ui.define(
  [
    "../../exceptions/UnauthorisedException",
    "../../exceptions/BadRequestException",
    "../../security/authenticate"
  ],
  function(UnauthException, BadRequestException, Authentication) {
    return {
      handleAsync: async function(pricelistId) {
        var token = await Authentication.getToken();

        var url = `${appconfig.host}/pricelists`

        if(pricelistId){
          url += `/${pricelistId}`
        }

        var response = await fetch(
          url,
          {
            method: "GET",
            mode: "cors",
            headers: {
              Authorization: `Bearer ${token.accessToken}`,
              "content-type": "application/x-www-form-urlencoded"
            }
          }
        ).catch(error => {
          console.log(error);
          throw new UnauthException("Request Failed", error);
        });

        if (response.status === 200) {
          var details = await response.json();
          return details || true;
        }
        if (response.status === 204) {
          return false;
        }
        if (response.status === 400) {
          console.log(response.json());
          throw new BadRequestException();
        }
      }
    };
  }
);
