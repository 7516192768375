sap.ui.define(
    [
      "../../exceptions/UnauthorisedException",
      "../../exceptions/BadRequestException",
      "../../security/authenticate"
    ],
    function(UnauthException, BadRequestException, Authentication) {
      
      const getQuery = function(params){
        if(params){
            
            var esc = encodeURIComponent
            var query = Object.keys(params)
                .map(k => esc(k) + (!params[k])?'': '=' + esc(params[k]))
                .join('&')
            return query
        }
    }
      return   async function(contactId, filter) {
          var token = await Authentication.getToken();

          var url = `${appconfig.host}/contacts/${contactId}/newcustomers`

          var options = {
            method: "GET",
            mode: "cors",
            headers: {
              Authorization: `Bearer ${token.accessToken}`,
              "content-type": "application/x-www-form-urlencoded"
            }
          }

          if(Object.keys(filter).length){
            url += `?${getQuery(filter)}`
          }



          var response = await fetch(url, options).catch(error => {
            console.log(error);
            throw new UnauthException("Request Failed", error);
          });
  
          if (response.status === 200) {
            var details = await response.json();
            return details || true;
          }
          if (response.status === 201) {
            return null;
          }
  
          if (response.status === 400) {
            console.log(response.json());
            throw new BadRequestException();
          }
        }
      }
    
  );
  