sap.ui.define([
    "../exceptions/UnauthorisedException",
    "../security/authenticate"
], function(UnauthException, Authentication) {
    return {
        handleUpdateAsync : async function(changes){            
            var token = await Authentication.getToken()
            var response = await fetch(`${appconfig.host}/pricelists/items`,{
                method: 'PATCH',
                mode: "cors",
                headers:{
                    "Authorization":`Bearer ${token.accessToken}`,
                    "Content-Type":"application/json"
                },
                body: JSON.stringify(changes)
            })
            .catch((error)=>{
                console.log(error)
                throw new UnauthException("Fetch Failed", error)
            })

            if(response.status===200){
                return {success:true, new: false}
            }  
            return {success: false}
        },
        handlePatchAsync : async function({
                listId, 
                id, 
                customerPartNumber, 
                itemCode, 
                itemId, 
                reorderPackSize, 
                unitOfMeasure}){
            
            var item = {
                CustomerPartNumber:customerPartNumber, 
                ItemCode:itemCode, 
                ItemId:itemId, 
                ReorderPackSize:reorderPackSize, 
                UnitOfMeasure:unitOfMeasure}

            var token = await Authentication.getToken()
            var response = await fetch(`${appconfig.host}/pricelists/${listId}/item/${id}`,{
                method: 'PATCH',
                mode: "cors",
                headers:{
                    "Authorization":`Bearer ${token.accessToken}`,
                    "Content-Type":"application/json"
                },
                body: JSON.stringify(item)
            })
            .catch((error)=>{
                console.log(error)
                throw new UnauthException("Fetch Failed", error)
            })

            if(response.status===200){
                return {success:true, new: false}
            }  
            return {success: false}
        }
    }
})