sap.ui.define(
    [
      "./delete",
      "./add",
      "./get",
      "./getnew"
    ],
    function(d,a,g,n) {
      return {
        delete: d  ,
        add: a  ,
        get: g  ,
        new: n
      };
    }
  );
  