sap.ui.define(
  [
    "../../exceptions/UnauthorisedException",
    "../../exceptions/BadRequestException",
    "../../security/authenticate"
  ],
  function(UnauthException, BadRequestException, Authentication) {
    return {
      handleAsync: async function({ Name }) {
        var token = await Authentication.getToken();
        var body = JSON.stringify({ Name });
        var response = await fetch(`${appconfig.host}/pricelists/create`, {
          method: "POST",
          mode: "cors",
          body: body,
          headers: {
            Authorization: `Bearer ${token.accessToken}`,
            "content-type": "application/x-www-form-urlencoded"
          }
        }).catch(error => {
          console.log(error);
          throw new UnauthException("Request Failed", error);
        });

        if (response.status === 201) {
          var details = await response.json();
          return details || true;
        }

        if (response.status === 400) {
          console.log(response.json());
          throw new BadRequestException();
        }
      }
    };
  }
);
