sap.ui.define([
	"jQuery.sap.storage",
    "../exceptions/UnauthorisedException",
    "../security/authenticate"
], function(Storage, UnauthException, Authentication) {
    return {
        handleAsync : async function(){
            var token = await Authentication.getToken()
            var response = await fetch(`${appconfig.host}/customers`,{
                method: 'GET',
                mode: "cors",
                headers:{
                    "Authorization":`Bearer ${token.accessToken}`,
                    "content-type":"application/x-www-form-urlencoded"
                },
            })         
            .catch((error)=>{
                console.log(error)
                throw new UnauthException("Login Failed", error)
            })

            if(response.status===200){
                var companies = await response.json()
                return companies
            }
        }
    }
})