
/* global sha256:true */

sap.ui.define([
    "../exceptions/UnauthorisedException",
    "../security/authenticate",
    "sha256"
], function(UnauthException, Authentication,sha256) {
    return {
        handleAsync : async function(p){
            var body = JSON.stringify({password:sha256(p)})
            var token = await Authentication.getToken()
            var response = await fetch(`${appconfig.host}/user/resetpassword`,{
                method: 'POST',
                mode: "cors",
                body: body,
                headers:{
                    "Authorization":`Bearer ${token.accessToken}`,
                    "content-type":"application/json"
                },
            })         
            .catch((error)=>{
                console.log(error)
                throw new UnauthException("Request Failed", error)
            })

            if(response.status===200){
                return true;
            }
            return false;
        }
    }
})