sap.ui.define([
    "univar/tcard/controller/BaseController",
	"sap/ui/model/json/JSONModel",
    "univar/tcard/src/api-comm/oData/get-odata-service",
    "sap/ui/model/Filter",
    "sap/ui/model/FilterOperator",
	"sap/ui/model/FilterType",
	'sap/base/util/deepExtend',
	'sap/m/ColumnListItem',
	'sap/m/Input',
	'sap/m/Text',
	'sap/m/CheckBox',
    "univar/tcard/src/api-comm/customer-cupboard",
    "univar/tcard/src/api-comm/pricelist-items",
    "univar/tcard/src/api-comm/pricelists",
    "univar/tcard/src/api-comm/warehouses"
], function(BaseController, JSONModel, oDataService, Filter,Operator,FilterType,deepExtend,ColumnListItem, Input, Text, CheckBox, Cupboard, PricelistItems, Pricelists, Warehouses) {
    "use strict";
    
	return BaseController.extend("univar.tcard.controller.Admin.customer-admin-split", {
        
        onItemPress: function(oEvent){

            var view = this.getView()
			var oItem = oEvent.getParameters().listItem;
			var oCtx = oItem.getBindingContext('odata');
			var path = oCtx.getPath();
            view.byId("detail").bindElement({path:`odata>${path}`, parameters:{$expand:"Orders"}});            
            this.updatePriceList(view)
        },
        updatePriceList:function(view){
            var customerId = this.getSelectedCustomerId()
            view = view||this.getView()
            Cupboard.handleGetAsync(customerId).then(result=>{
                view.getModel().setProperty('/Cupboard',result)
            });
            Cupboard.handleGetPriceListAsync(customerId).then(result=>{
                view.getModel().setProperty('/PriceList',result)
            });
        },
        onDrop: function(oEvent){
			var oDragged = oEvent.getParameter("draggedControl"),
				oDropped = oEvent.getParameter("droppedControl"),
				sInsertPosition = oEvent.getParameter("dropPosition"),
				oGrid = oDragged.getParent(),
				oData = this.getView().getModel().getProperty('/Cupboard'),
				iDragPosition = oGrid.indexOfItem(oDragged),
                iDropPosition = oGrid.indexOfItem(oDropped),
                customerId = this.getSelectedCustomerId();

			// remove the item
			var oItem = oData[iDragPosition];
			oData.splice(iDragPosition, 1);

			if (iDragPosition < iDropPosition) {
				iDropPosition--;
			}

			// insert the control in target aggregation
			if (sInsertPosition === "Before") {
				oData.splice(iDropPosition, 0, oItem);
			} else {
				oData.splice(iDropPosition + 1, 0, oItem);
            }
            
            Cupboard.handlePatchAsync(customerId,oData.map(d=>d.PriceListItemId));

            this.getView().getModel().setProperty('/Cupboard',oData)
        },
        onWarehouseChanged: function(oEvent){
            //var selected = oEvent.getSource().getProperty('selectedKey');
            //var view = this.getView()
            //setTimeout(()=>this.updatePriceList(view), 500);
        },
        onPriceListChange: function(oEvent){
            var view = this.getView()
            setTimeout(()=>this.updatePriceList(view), 500);
        },
        inCupboardSelect:function(oEvent){
            var view = this.getView()

            var source = oEvent.getSource()
            var context = source.getBindingContext()
            var path = context.getPath()            
            var item = view.getModel().getProperty(path);
            var id = item.PriceListItemId

            var customerId = this.getSelectedCustomerId()

            var selected = oEvent.getParameter('selected')

            if(selected){
                Cupboard.handlePostAsync(customerId, [id]).then(result=>{
                    if(result.success){
                        Cupboard.handleGetAsync(customerId).then(result=>{
                            view.getModel().setProperty('/Cupboard',result)
                        });
                    }else{
                        Cupboard.handleGetPriceListAsync(customerId).then(result=>{
                            view.getModel().setProperty('/PriceList',result)
                            showToast('Unable to Save', 'Danger')
                        });
                    }
                })
            }else{
                Cupboard.handleDeleteAsync(customerId, [id]).then(result=>{
                    if(result.success){
                        Cupboard.handleGetAsync(customerId).then(result=>{
                            view.getModel().setProperty('/Cupboard',result)
                        });
                    }else{
                        Cupboard.handleGetPriceListAsync(customerId).then(result=>{
                            view.getModel().setProperty('/PriceList',result)
                            showToast('Unable to Save', 'Danger')
                        });
                    }
                })
            }
        },
		onInit: function() {
			var oRouter = this.getRouter();
            oRouter.getRoute("customers").attachMatched(this.onRouteMatched, this);

            this._oninit()
		},
        onUpdateFinished : function(oEvent){

            var oList = oEvent.getSource();
            var aItems = oList.getItems();
            if(aItems && aItems.length){
                var oItem = aItems[0]
                oList.setSelectedItem(oItem)
                var oCtx = oItem.getBindingContext('odata');
                var path = oCtx.getPath();
                var view = this.getView()
                view.byId("detail").bindElement({path:`odata>${path}`, parameters:{$expand:"Orders"}});
                    
                var customerId = this.getSelectedCustomerId()
                Cupboard.handleGetAsync(customerId).then(result=>{
                    view.getModel().setProperty('/Cupboard',result)
                });
                Cupboard.handleGetPriceListAsync(customerId).then(result=>{
                    this.priceList = result;
                    view.getModel().setProperty('/PriceList',this.priceList)
                    
                    this.oTable = this.byId("PriceListTable");
                    this.oReadOnlyTemplate = this.byId("PriceListTable").removeItem(0);
                    this.rebindTable(this.oReadOnlyTemplate, "Navigation");
                    this.oEditableTemplate = new ColumnListItem({
                        cells: [
                            new CheckBox({
                                selected: "{IsInCupboard}"
                            }), new Text({
                                text: "{ItemCode}"
                            }), new Input({
                                value: "{CustomerPartNumber}",
                                //description: "{WeightUnit}"
                            }), new Input({
                                value: "{ReorderPackSize}"
                            }), new Text({
                                text: "{UnitOfMeasure}"
                            })
                        ]
                    });
            
                });
            }
        },

		rebindTable: function(oTemplate, sKeyboardMode) {
			this.oTable.bindItems({
                path: "/PriceList",
                sorter:{path:'ItemCode'},
				template: oTemplate,
				templateShareable: true,
				key: "ProductId"
			}).setKeyboardMode(sKeyboardMode);
		},

		onEdit: function() {
			this.aProductCollection = deepExtend([], this.getView().getModel().getProperty("/PriceList"));
			this.byId("editButton").setVisible(false);
			this.byId("saveButton").setVisible(true);
			this.byId("cancelButton").setVisible(true);
			this.rebindTable(this.oEditableTemplate, "Edit");
		},

		onSave: function() {

            var changes = this.getView().getModel().getProperty('/PriceList')
                .map(n=>{
                    var o = this.aProductCollection.find(i=>i.PriceListItemId === n.PriceListItemId)
                    return {
                        PriceListItemId:n.PriceListItemId, 
                        CustomerPartNumber:n.CustomerPartNumber,  
                        ReorderPackSize: n.ReorderPackSize, 
                        oldCustomerPartNumber:o.CustomerPartNumber, 
                        oldReorderPackSize:o.ReorderPackSize
                    }
                })
                .filter(i=>i.CustomerPartNumber !== i.oldCustomerPartNumber || i.ReorderPackSize !== i.oldReorderPackSize)
                .map(i=>{return {Id:i.PriceListItemId, CustomerPartNumber:i.CustomerPartNumber, ReorderPackSize:i.ReorderPackSize}})
            PricelistItems.handleUpdateAsync(changes)
            .then(e=>console.log(e))
            .catch(e=>console.log(e))

			this.byId("saveButton").setVisible(false);
			this.byId("cancelButton").setVisible(false);
			this.byId("editButton").setVisible(true);
			this.rebindTable(this.oReadOnlyTemplate, "Navigation");
		},

		onCancel: function() {
			this.byId("cancelButton").setVisible(false);
			this.byId("saveButton").setVisible(false);
			this.byId("editButton").setVisible(true);
			this.getView().getModel().setProperty("/PriceList", this.aProductCollection);
			this.rebindTable(this.oReadOnlyTemplate, "Navigation");
		},
        getSelectedCustomerId:function(view){
            view = view || this.getView()
            var path = view.byId('customer-master-list').getSelectedContextPaths()[0]
            var match = path.match(new RegExp('(?<=\\/CustomerViews\\().*(?=\\))','g')); 
            return match[0];

        },
        onPricelistItemUpdated: function(oEvent){
            //PricelistItems
        },
        search : function(oEvent){
            var query = oEvent.getParameter('query')
            if(query){
                var filter = new Filter({path:"CardCode", operator: Operator.Contains, value1:query})
            }
            
            var logTable = this.getView().byId('customer-master-list')
            logTable.getBinding("items").filter(filter, FilterType.Application);
        },
        priceListFilter : function(oEvent){
            var query = oEvent.getParameter("query")
            this.getView().getModel().setProperty('/PriceList',this.priceList.filter(p=>p.ItemCode.includes(query)));
        },
        priceList:[],
		onRouteMatched : function(oEvent){           
            
            this.getView().setModel(new JSONModel())
            
            this.isLoggedIn().then(result=>{

                if (result){

                    Promise.all([Warehouses.handleGetAsync(),oDataService, Pricelists.get.handleAsync()])
                    .then(result=>{


                        this.getView().getModel().setProperty('/whsList',result[0]);
                        this.getView().getModel().setProperty('/prcList',result[2]);

                        console.log("oData", this.getView().getModel().oData)

                        var oModel = result[1]

                        var model = sap.ui.getCore().getModel('customer_search') || new JSONModel();

                        if(sap.ui.getCore().getModel('customer_search')){
                            this.onSearch(oEvent)
                        }

                        model.oData.text = model.oData.text || "";
                        this.getView().setModel(model,'customer_search');
                        this.getView().setModel(oModel, 'odata');
                        
                        oModel.getMetaModel().requestObject("/Customers/").then(res=>{
                            var oLastModified = oModel.getMetaModel().getLastModified();
                            console.log("Last-Modified: " + oLastModified,
                            oLastModified && oLastModified.toISOString(),
                            "univar.controllers.CustomerAdmin.customer-admin-master");
                            
                        }).catch(err=>console.log(err))


                    })
                }else{
                    this.navigate('login')
                    this.showToast('login error','Danger')
                }
            }).catch(err=>{
                console.log(err)
                this.showToast(err,'Danger')
            })
        }
    })
})