sap.ui.define([
    "../exceptions/UnauthorisedException",
    "../security/authenticate"
], function(UnauthException, Authentication) {
    return {
        handleGetAsync : async function(warehouseId){

            var url = `${appconfig.host}/warehouses`
            if(warehouseId){
                url += `/${warehouseId}`
            }

            var token = await Authentication.getToken()
            var response = await fetch(url,{
                method: 'GET',
                mode: "cors",
                headers:{
                    "Authorization":`Bearer ${token.accessToken}`
                }
            })
            .catch((error)=>{
                console.log(error)
                throw new UnauthException("Fetch Failed", error)
            })

            if(response.status===200){
                return await response.json();
            }            
        }
    }
})