sap.ui.define([
	"jQuery.sap.storage",
    "../exceptions/UnauthorisedException",
    "../exceptions/ArgumentException",
    "../security/authenticate",
], function(Storage, UnauthException,ArgumentException, Authentication) {
    return {
        handleAsync : async function(id){
            if(!id){
                throw new ArgumentException("Customer Id was not supplied")
            }
            var token = await Authentication.getToken()
            var response = await fetch(`${appconfig.host}/customers/${id}/items`,{
                method: 'GET',
                mode: "cors",
                headers:{
                    "Authorization":`Bearer ${token.accessToken}`,
                    "content-type":"application/x-www-form-urlencoded"
                },
            })         
            .catch((error)=>{
                console.log(error)
                throw new UnauthException("Login Failed", error)
            })

            if(response.status===200){
                var items = await response.json()
                return items
            }
        }
    }
})