sap.ui.define(['sap/ui/base/Object'],function(BaseObject){
    var BusyStack =  BaseObject.extend("univar.tcard.src.utilities.busyStack",  {
		constructor : function() {

			BaseObject.call(this);

			/**
			 * A map of arrays of event registrations keyed by the event names
			 * @private
			 */
			this.stack = {};

		}
    })
    BusyStack.prototype.addBusy = function(view){
        var viewId = view.getId()
        view.setBusy(true);
        if(!this.stack.hasOwnProperty(viewId)){
            this.stack[viewId] = [];
        }
        var func = ()=> {
            var i = this.stack[viewId].find(f=>f===func);
            this.stack[viewId].splice(i,1)
            if(this.stack[viewId].length === 0){
                view.setBusy(false);
            }
        }
        this.stack[viewId].push(func);
        return func;
    }
    return BusyStack
})
