
/* global sha256:true */

sap.ui.define([
    "../exceptions/UnauthorisedException",
    "sha256"
], function(UnauthException,sha256) {
    return {
        handleAsync : async function(f,l,e,t,p,u){
            var body = JSON.stringify({
                Email : e,
                FirstName : f,
                LastName : l,
                Tel : t,
                Password : sha256(p),
                Username : u
            })
            var response = await fetch(`${appconfig.host}/user/signup`,{
                method: 'POST',
                mode: "cors",
                body: body,
                headers:{
                    "content-type":"application/json"
                },
            })         
            .catch((error)=>{
                console.log(error)
                throw new UnauthException("Request Failed", error)
            })

            if(response.status===201){
                return {successful:true};
            }
            if(response.status===409){
                return {successful:false, message:"The email address may already exist"};
            }
            return {successful:false, message:"Failed.  Please contact your administrator"};
        }
    }
})