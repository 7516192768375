sap.ui.define([
    "univar/tcard/controller/BaseController",
	"sap/ui/model/json/JSONModel",
    "univar/tcard/src/api-comm/oData/get-odata-service",
    "sap/ui/model/Filter",
    "sap/ui/model/FilterOperator",
	"sap/ui/model/FilterType"
], function(BaseController, JSONModel,oDataService, Filter,Operator,FilterType) {
    "use strict";
    
	return BaseController.extend("univar.tcard.controller.Admin.item-admin-split", {
        
        onItemPress: function(oEvent){

			var oItem = oEvent.getParameters().listItem;
			var oCtx = oItem.getBindingContext();
			var path = oCtx.getPath();
			this.getView().byId("detail").bindElement({path, parameters:{$expand:"ItemStocks"}});
        },
		onInit: function() {
			var oRouter = this.getRouter();
			oRouter.getRoute("items").attachMatched(this.onRouteMatched, this);
		},
        search : function(oEvent){
            try{
                var query = oEvent.getParameter('query')
                if(query){
                    var filter = new Filter({path:"ItemCode", operator: Operator.Contains, value1:query})
                }
                
                var logTable = this.getView().byId('item-master-list')
                logTable.getBinding("items").filter(filter, FilterType.Application);
            }catch(err){
                this.showToast("An unexpected error has occured", "Danger")
                console.log(err)
            }
        },
		onRouteMatched : function(oEvent){
			
            this.isLoggedIn().then(result=>{

                if (result){
                    oDataService.then(oModel=>{

                        var model = sap.ui.getCore().getModel('item_search') || new JSONModel();

                        if(sap.ui.getCore().getModel('item_search')){
                            this.onSearch(oEvent)
                        }

                        model.oData.text = model.oData.text || "";
                        this.getView().setModel(model,'item_search');
                        this.getView().setModel(oModel);
                        
                        oModel.getMetaModel().requestObject("/Items/").then(res=>{
                            var oLastModified = oModel.getMetaModel().getLastModified();
                            console.log("Last-Modified: " + oLastModified,
                            oLastModified && oLastModified.toISOString(),
                            "univar.controllers.ItemAdmin.item-admin-master");
                            
                        }).catch(err=>console.log(err))

                    })
                }else{
                    this.navigate('login')
                    this.showToast('login error','Danger')
                }
            }).catch(err=>{
                console.log(err)
                this.showToast(err,'Danger')
            })
        }
    })
})