sap.ui.define([
	"univar/tcard/controller/BaseController",
	"univar/tcard/src/api-comm/post-reset-password",
	"sap/m/MessageBox",
	"sap/ui/model/json/JSONModel",
	'sap/m/MessageToast',
	"../src/utilities/busyStack"
], function(BaseController, PasswordRecovery, MessageBox, JSONModel, Toast) {
	"use strict";

	return BaseController.extend("univar.tcard.controller.PasswordRecovery", {
		
		_onButtonPress: async function(oEvent) {
			var view = this.getView()
			//view.setBusy(true)
			var setNotBusy=this.getBusyStack().addBusy(view)
			var vModel = view.getModel()
			var email = vModel.oData.email;
			if(email){ 
				var success = await PasswordRecovery.handleAsync(email).catch((error)=>{

				})
				//view.setBusy(false)
				setNotBusy()
				if(success){
					MessageBox.success(
						`Thank you. We are searching for your account. If your account can be located then we will send instructions for resetting your password to the email address '${email}'`,
						{
							//styleClass: bCompact ? "sapUiSizeCompact" : "",
							onClose:function(oAction){
								sap.ui.getCore().controller.navigate('login')
							}
						}
					);
				}
			}

		},
		onInit: function() {
			var oRouter = this.getRouter();
			oRouter.getRoute("passwordrecovery").attachMatched(this._onRouteMatched, this);
			this._oninit()
		},		
		_onRouteMatched : function (oEvent) {
			var vModel = new JSONModel({email:""})
			this.getView().setModel(vModel)
		}
	});
}, /* bExport= */ true);
