sap.ui.define([
    "univar/tcard/controller/BaseController",
	'sap/ui/core/Fragment',
	"univar/tcard/src/api-comm/customer/get-customer",
	"univar/tcard/src/api-comm/get-company-items",
	"univar/tcard/src/api-comm/my-basket",
	"univar/tcard/src/api-comm/get-item-orderhistory",
	"univar/tcard/src/api-comm/get-orderdetails",
	"sap/ui/model/json/JSONModel",
	'sap/ui/model/Filter'
], function(BaseController, Fragment, GetCompany, GetCompanyItems, MyBasket, ItemOrders, OrderDetails, JSONModel, Filter) {
	"use strict";
	var companyId;
	var controller;
	var overOrderHandled = false;
	const getIndexFrompath = function(path){
        var sub = path.replace(new RegExp('\/(data|orders)\/','g'), '')
        var parsed = parseInt(sub, 10);
        if (isNaN(parsed)) {
             return 0 
        }
        return parsed
	}
	const setOrder = function(view, index){
		var model = view.getModel()
		var item = model.oData.orders[index]
		view.setModel(new JSONModel(item), 'order')

		var table = view.byId("orderItemTable")
		//table.setBusy(true)
		var setNotBusy=this.getBusyStack().addBusy(table)

		OrderDetails.handleAsync(item.Id, companyId)
		.then((result)=>{
			if(result){
				
				var newData = model.oData
				newData.orderItems = result.OrderItems;
		
				model.setData(newData)
			}
			//table.setBusy(false)
			setNotBusy()

		}).catch((error)=>{
			//table.setBusy(false)
			setNotBusy()
		})
	}
	
	const selectOrder = function(order){
		order.CreatedDate = new Date(order.CreatedDate).toLocaleDateString()
		return order
	}

	return BaseController.extend("univar.tcard.controller.Companies.shoppinglist-split", {
		 setItem: function(view, index){
			var model = view.getModel()
			var item = model.oData.data[index]
	
			//model.setData({...model.oData, selectedItem : item})
	
			view.setModel(new JSONModel(item), 'item')
	
			var table = view.byId("orderTable")
			//table.setBusy(true)
			var setNotBusy=this.getBusyStack().addBusy(table)
	
			ItemOrders.handleAsync(item.Id, companyId)
			.then((result)=>{
				if(result && result.length){
					
					var newData = model.oData
					newData.orders = result.map(selectOrder) 
	
					model.setData(newData)
				}else{
					model.setProperty('/orders',null);
					//model.destroy();
				}
				//table.setBusy(false)
				setNotBusy()
	
			}).catch((error)=>{
				//table.setBusy(false)
				setNotBusy()
				this.showToast("Failed to download order history", "Danger")
			})
		},
	
		_onCheckoutButtonPress: function(oEvent){
			if(this.getView().getModel().getProperty('/basket/length')){
				var poNumber = this.getView().byId("purchaseOrderNumber").getValue()
				MyBasket.handlePutToOrderAsync(companyId, poNumber)
				.then(result=>{
					if(result.success){
						this.showToast("Order sent.")
						this.getView().getModel().setProperty('/basket', [])
						this.getUpdateItemStatus(this)
						this.getView().byId("purchaseOrderNumber").setValue("")
					}else{
						this.showToast("unable to send Order")
					}
				})
			}else{
				this.showToast('No items to check out.')
			}
		},
		itemsOrderStatusUpdated : function(userId, customerId){
			if(customerId === companyId){
				GetCompanyItems.handleAsync(customerId )
				.then(result=>{
					var data = sap.ui.getCore().controller.getView().getModel().getProperty('/data');
					if(data){
						data.forEach((d,i) => {
							var item = result.find(r=>r.Id === d.Id)
							if(item && item.Status != d.Status){
								sap.ui.getCore().controller.getView().getModel().setProperty('/data/'+i+'/Status',item.Status)
							}
						});
					}
					else{
						var items = sap.ui.getCore().controller.getView().getModel().getProperty('/0/Items')
						if(items){
							items.forEach((d,i) => {
								var item = result.find(r=>r.Id === d.Id)
								if(item && item.Status != d.Status){
									sap.ui.getCore().controller.getView().getModel().setProperty('/0/Items/'+i+'/Status',item.Status)
								}
							});
						}
					}
				})
			}
		},
		onButtonPress: function(oEvent){
			if(!this._addToBasketDialog){
				Fragment.load({
					name:'univar.tcard.view.Companies.AddToBasket',
					controller:this
				}).then(function(dialog){
					this._addToBasketDialog = dialog
					var model = this.getView().getModel('item')

					var slider = sap.ui.getCore().byId('orderSlider')
					var input = sap.ui.getCore().byId('orderAmount')
					input.setValue(model.oData.ReorderPackSize)
					slider.setMin(model.oData.ReorderPackSize)
					slider.setMax(this.formatReorderMax(model.oData.ReorderPackSize))
					slider.setStep(model.oData.ReorderPackSize)
					slider.setEnableTickmarks(true)

					this._addToBasketDialog.setModel(model)
					this._addToBasketDialog.open()
				}.bind(this))
			}
		},
		onLineItemButtonPress: function(oEvent){
			var view = this.getView()
			var model = view.getModel()
			var path = oEvent.getSource().getBindingContext().getPath()
			var obj = model.getProperty(path)

			var item = model.oData.data.find(i=>i.Id === obj.ItemId)

			item.Quantity = obj.Quantity
			item.Path = path
			item.BasketId = obj.Id
			
			if(!this._editIteminCheckoutDialog){
				Fragment.load({
					name:'univar.tcard.view.Companies.editItem',
					controller:this
				}).then(function(dialog){
					this._editIteminCheckoutDialog = dialog
					var model = new JSONModel(item)


					this._editIteminCheckoutDialog.setModel(model)
					this._editIteminCheckoutDialog.open()
				}.bind(this))
			}


		},
		onInputAmountLiveChange: function(oEvent){
			var value = parseInt( oEvent.getParameter('newValue') )

			var slider = sap.ui.getCore().byId('orderSlider')
			slider.setValue(value)
		},
		onInputAmountChange: function(oEvent){
			var value = parseInt( oEvent.getParameter('newValue') )
			var model = this._addToBasketDialog.getModel();

			if((model.oData.ReorderPackSize) && !(value % model.oData.ReorderPackSize)){
				// oEvent.bubbleEvent = false;
				// Toast.show(`Must order in multiples of ${model.oData.ReorderPackSize}`,{autoClose: true,duration: 1000,animationDuration: 300})
			}else{
				model.oData.OrderAmt = value
				this._addToBasketDialog.setModel(model)
			}

		},
		onChangeSlider: function(oEvent){
			var value = oEvent.getParameter('value');
			var model = this._addToBasketDialog.getModel();
			model.oData.OrderAmt = value
			this._addToBasketDialog.setModel(model)
			var input = sap.ui.getCore().byId('orderAmount')
			input.setValue(value)
		} ,

		

		onBasketDialogClose: async function(oEvent){
			var button = oEvent.getSource()
			if(button.sId){
				if(button.sId=="ok"){

					var item = this.getView().getModel('item').oData

					var input = sap.ui.getCore().byId('orderAmount')

					var quantity = input.getValue();

					var payload = {
						itemId:item.Id, 
						unitOfMeasureId:item.UnitOfMeasureId, 
						quantity}

					if(!overOrderHandled && item.InStock < quantity && !this._quantityWarningDialog){
						Fragment.load({
							name:'univar.tcard.view.Companies.QuantityWarning',
							controller:this
						}).then(function(dialog){
							this._quantityWarningDialog = dialog
							this._quantityWarningDialog.setModel(new JSONModel(payload))
							this._quantityWarningDialog.open()
						}.bind(this))
					}else{
						this.postBasket(payload)
						this._addToBasketDialog.destroy()
						this._addToBasketDialog = undefined
					}
				}else if(button.sId=="cancel"){
					this._addToBasketDialog.destroy()
					this._addToBasketDialog = undefined
				}
			}

		},
		onQuantityWarningDialogClose : function(oEvent){
			var button = oEvent.getSource()
			if(button.sId){
				var payload = this._quantityWarningDialog.getModel().oData
				this._quantityWarningDialog.destroy()
				this._quantityWarningDialog = undefined
				if(button.sId==="proceed"){					
					this.postBasket(payload)
					this._addToBasketDialog.destroy()
					this._addToBasketDialog = undefined
				}else if(button.sId==="canx"){					
					this._addToBasketDialog.destroy()
					this._addToBasketDialog = undefined
				}
			}
		},
		postBasket: async function(payload){
			var result = await MyBasket.handlePostAsync(companyId , payload)
			
			if(result && result.success){
				this.refreshBasket()
				.then(()=>{
					console.log("basket refresh", "ok")
				}).catch(err=>console.log("basket refresh", err))
				if(result.new){
					this.showToast("Item added to basket",{autoClose: true,duration: 1000,animationDuration: 300})
				}else{
					this.showToast("Item updated in basket",{autoClose: true,duration: 1000,animationDuration: 300})
				}
			}else{
				this.showToast("Unable to complete the transaction.\nPlease refresh the page and try again.",{autoClose: true,duration: 1000,animationDuration: 300})
			}
		},
		onEditItemDialogClose: function(oEvent){
			var button = oEvent.getSource()
			if(button.sId){
				var view = this.getView()
				var item = this._editIteminCheckoutDialog.getModel().oData

				if(button.sId==="ok"){
					var qtyPath = item.Path + '/Quantity'
					if(view.getModel().getProperty(qtyPath)!==item.Quantity){
						view.getModel().setProperty(qtyPath, item.Quantity)
						
						MyBasket.handlePatchAsync(item.BasketId, item.Quantity).then(()=>{
							//noop
						}).catch(err=>{
							console.log(err)
							this.refreshBasket();
						})
					}
				}
				else if(button.sId==="remove"){
					var basket = view.getModel().oData.basket
					var index = basket.findIndex(b=>b.Id === item.BasketId)
					if((index+1)){
						basket.splice(index,1)
						view.getModel().setProperty('/basket', basket)
					}
					view.getModel().refresh()
					MyBasket.handleDeleteAsync(item.BasketId).then(()=>{
						//noop
					}).catch(err=>{
						console.log(err)
						this.refreshBasket()
					})
				}
				
				this._editIteminCheckoutDialog.destroy()
				this._editIteminCheckoutDialog = undefined
			}
		},
		_onObjectListItemPress: function(oEvent) {

            var view = this.getView()
            var path = oEvent.getParameters().listItem.getBindingContext().getPath()
            var index = getIndexFrompath(path)
            this.setItem(view, index)
			this.getSplitContentObj().toDetail(this.createId('details'))

		},
		onCheckoutButtonPress: function(oEvent){
			this.getSplitContentObj().toDetail(this.createId('details2'))
		},
		onDetailBack: function(oEvent){
			this.getSplitContentObj().backDetail()
		},
		onItemOrderPress : function(oEvent){
            var view = this.getView()
			var order = oEvent.getSource();
			var path = order.getBindingContext().getPath();
			var index = getIndexFrompath(path);
			
			setOrder(view, index)
			
			this.getSplitContentObj().toDetail(this.createId('orderDetails'))

		},
        onSearch : function(oEvt){
			var aFilters = [];
			var sQuery = oEvt.getSource().getValue();
			if (sQuery && sQuery.length > 0) {
				var filter = new Filter("Name", sap.ui.model.FilterOperator.Contains, sQuery);
				aFilters.push(filter);
			}

			// update list binding
			var list = this.byId("itemList");
			var binding = list.getBinding("items");
			binding.filter(aFilters, "Application");
		},
		onInit: function() {
			var oRouter = this.getRouter();
			oRouter.getRoute("company").attachMatched(this.onRouteMatched, this);
			var deviceModel = new sap.ui.model.json.JSONModel({
				isPhone: sap.ui.Device.system.phone
			});
			this.getView().setModel(deviceModel, "device");
		},
		onOrientationChange: function(oEvent){
		},
		onBackPress:function(oEvent){

			var oSplitApp = controller.getView().byId('shoppingList')
			var oMaster = oSplitApp.getMasterPages()[0];
			oSplitApp.backMaster(oMaster, "slide")
		},
		onDetailBackPress:function(oEvent){

			var oSplitApp = controller.getView().byId('shoppingList')
			var oDetail = oSplitApp.getDetailPages()[0];
			oSplitApp.backDetail(oDetail, "slide")
		},
		onRouteMatched : function(oEvent){
			var evt = oEvent
			controller = this;
			var args = evt.getParameter("arguments");
			if(!args || !args.hasOwnProperty("companyId")){
				this.getRouter().navTo("login");
			}
			companyId = args.companyId

			this.isLoggedIn().then((result)=>{
				if(result){
					var view = controller.getView()
					//view.setBusy(true)
					var setNotBusy=controller.getBusyStack().addBusy(view)

					Promise.all([GetCompany.handleAsync(companyId ), GetCompanyItems.handleAsync(companyId ), MyBasket.handleGetAsync(companyId )])
					.then((results)=>{
						var customer = results[0]
						var data = results[1]
						var basket = results[2]
						var oModel = new JSONModel({data, basket})
						view.setModel(oModel);
						var item = result[0];
						view.setModel(new JSONModel(item), 'item')
						view.setModel(new JSONModel(customer), 'customer')
						var itemList = view.byId("itemList")
						var items = itemList.getItems()
						if(items && items.length){
							items[0].setSelected(true)
							this.setItem(view, 0)
						}

						sap.ui.Device.orientation.attachHandler(controller.onOrientationChange)
						controller.onOrientationChange({landscape:sap.ui.Device.orientation.landscape})

						//shoppingList

						//view.setBusy(false)
						setNotBusy()
						// controller.polling = Polling;
						// controller.polling.start(controller.getUpdateItemStatus(controller), 30*1000)

					}).catch((error)=>{
						//data error
						//view.setBusy(false)
							setNotBusy()
					})
					if(controller.socket){
						controller.socket.itemsOrderStatusUpdated = controller.itemsOrderStatusUpdated;
					}
				}else{
					view.setBusy(false)
					controller.getRouter().navTo("login");
				}
			}).catch((error)=>{
				
				controller.getRouter().navTo("login");
			})
		},
		getUpdateItemStatus : function(controller){
			var updateItemStatus = async function(){
				var hasError = false
				var result = await GetCompanyItems.handleAsync(companyId )
				.catch(err=>{
					hasError = true
					console.log(err)
				})
				if(!hasError && result){
					controller.getView().getModel().setProperty('/data',result)
				}
			}
			return updateItemStatus
		},
		
		refreshBasket: async function(){
			var basket = await MyBasket.handleGetAsync(companyId)
			.catch(err=>console.log(err))
			
			var view = this.getView();
			var oModel = view.getModel();
			oModel.setProperty("/basket",basket)			
		},
		onExit: function() {
			companyId = undefined
			// this.polling.stop()
			// this.polling = undefined
		},
		getSplitContentObj: function(){
			var result = this.byId("shoppingList");
			if (!result) {
				
			}
			return result;
		},
		formatDelivered: function(del,ord){
			if(del!==ord && del!==0){
				return `${del} (${ord-del})`
			}
			return del;
		},
		formatBasketQuantity: function(reorderStep){
			var step = reorderStep ? reorderStep : 1
			return `Amount (step: ${step})`
		},
		formatReorderMax: function(reorderStep){
			var step = reorderStep ? reorderStep : 1
			if(step < 10){
				return step * 500
			}
			return step * 100
		},
		formatCount: function(items){
			return (items && items.length) || 0
		},
		highlightStatusFormatter: function(status){
			switch(status){
				case 43:
					return "Error";
				case 44:
					return "Warning";
				case 45:
					return "Success";
				default:
					return;
			}
		},
		visibleWithValueFormatter : function(value){
			return !!value
		},
		zeroWhenMissingFormatter: function(value){
			if(!value)
				return "0";
			return value.toString();
		}
	});
}, /* bExport= */ true);
