
var checks = null


var chars = {
    ' '     :' ',
    '!'	    :'!',
    '”'	    :'"',
    '#'	    :'#',
    '$'	    :'\\$',
    '%'	    :'%',
    '&'	    :'\\&',
    '’'	    :'’',
    '('	    :'\\(',
    ')'	    :'\\)',
    '*'	    :'\\*',
    '+'	    :'\\+',
    ','	    :',',
    '-'	    :'\\-',
    '.'	    :'\\.',
    '/'	    :'/',
    ':'	    :':',
    ';'	    :';',
    '<'	    :'<',
    '='	    :'=',
    '>'	    :'>',
    '?'	    :'\\?',
    '@'	    :'@',
    '['	    :'\\[',
    '\\'	:'\\\\',
    ']'	    :'\\]',
    '^'	    :'\\^',
    '_'	    :'_',
    '`'	    :'`',
    '{'	    :'\\{',
    '|'	    :'|',
    '}'	    :'\\}',
    '~'	    :'~'
    
}

const loadPolicy= async function(api){
    if(!checks){
        
        var result = await api.get();

        checks =  {
            weak : {                
                minLength : result.MinLengthWeak,
            },
            ok : {                
            },
            strong : {                
                minLength : result.MinLengthStrong,
            }
        }
        
        var minLowerCaseChars = result.MinLowerCase
        var minLowerCaseEnforced = result.MinLowerCaseEnforced
        var minUpperCaseChars = result.MinUpperCase
        var minUpperCaseEnforced = result.MinUpperCaseEnforced
        var minNumericChars = result.MinNumeric
        var minNumericEnforced = result.MinNumericEnforced
        var minSpecialChars = result.MinSpecial
        var minSpecialEnforced = result.MinSpecialEnforced
        var specialChars = BuildRegexString(result.Special)
        if(minLowerCaseEnforced){
            checks.weak.minLowerCaseChars = minLowerCaseChars
        }else{
            checks.ok.minLowerCaseChars = minLowerCaseChars
        }
        if(minUpperCaseEnforced){
            checks.weak.minUpperCaseChars = minUpperCaseChars
        }else{
            checks.ok.minUpperCaseChars = minUpperCaseChars
        }
        if(minNumericEnforced){
            checks.weak.minNumericChars = minNumericChars
        }else{
            checks.ok.minNumericChars = minNumericChars
        }
        if(minSpecialEnforced){
            checks.weak.minSpecialChars = minSpecialChars
            checks.weak.specialChars = specialChars
        }else{
            checks.ok.minSpecialChars = minSpecialChars
            checks.ok.specialChars = specialChars
        }
    }
}

const BuildRegexString=function(str){
    var result = ''
    for (const c of str) {
        if(chars.hasOwnProperty(c)){
            result += chars[c];
        }
    }
    return result
}


const checkPasses = function(str, pwd){
    
    if(!pwd){
        return false;
    }
    if(!str){
        return true;
    }
    var rex = 
        hasRexPart(str, "minNumericChars") +
        hasRexPart(str, "minSpecialChars") +
        hasRexPart(str, "minLength") +
        hasRexPart(str, "minLowerCaseChars") +
        hasRexPart(str, "minUpperCaseChars")

    var regex = new RegExp(rex)

    return regex.test(pwd)

}

const hasRexPart = function(str, strPart){
    if(str.hasOwnProperty(strPart) && str[strPart]){
        switch(strPart){
            case 'minNumericChars' : 
                return getOccurences('.*[0-9]',str[strPart]  )
            case 'minSpecialChars' : 
                return getOccurences(`.*[${str['specialChars']}]`,str[strPart]  )
            case 'minLength' : 
                return `(?=^.{${str[strPart]},})`
            case 'minLowerCaseChars' : 
                return getOccurences('.*[a-z]',str[strPart]  )
            case 'minUpperCaseChars' :  
                return getOccurences('.*[A-Z]',str[strPart]  )  
            default:
                return ''
        }
    }
    return ''
}

const getOccurences = function(txt, count){
    return `(?=^${txt.repeat(count)})`
}

const checkStrength = function(pwd){

    if(!checks.hasOwnProperty("weak") || !checkPasses(checks.weak, pwd)){
        return 'Invalid';
    }

    if(!checks.hasOwnProperty("ok") || !checkPasses(checks.ok, pwd)){
        return 'Weak';
    }

    if(!checks.hasOwnProperty("strong") || !checkPasses(checks.strong, pwd)){
        return 'OK';
    }
    
    return 'Strong'

}

sap.ui.define([
    "../api-comm/password-policy",
], async function(PasswordPolicy) {
    checks = null
    await loadPolicy(PasswordPolicy)
    return {
        reloadPolicy : async function(){
            checks = null
            await loadPolicy(PasswordPolicy);
        },
        setPasswordStrength : function (ctrl, value){
            var isValid  = false;
            if(!value){
                ctrl.setValueState(sap.ui.core.ValueState.None)
            }else{
    
                var str = checkStrength(value)
                ctrl.setValueStateText(str)
                switch(str){
                    case "Invalid":
                        ctrl.setValueState(sap.ui.core.ValueState.Error)
                        break;
                    case "Weak":
                        ctrl.setValueState(sap.ui.core.ValueState.Warning)
                        isValid = false;
                        break;
                    case "OK":
                        ctrl.setValueState(sap.ui.core.ValueState.Information)
                        isValid = true;
                        break;
                    case "Strong":
                        ctrl.setValueState(sap.ui.core.ValueState.Success)
                        isValid = true;
                        break;
                }
            }
            return isValid;
        },
        checkPasswords : function(view, confctrl){
            
            var model = view.getModel()
            var value = model.oData.conf 
            if(!value){
                confctrl.setValueState(sap.ui.core.ValueState.None)
            }else{
                if(model.oData.pass===model.oData.conf){
                    confctrl.setValueState(sap.ui.core.ValueState.Success)
                    if(model.oData.valid ){
                        return true;
                    }
                }else{
                    confctrl.setValueState(sap.ui.core.ValueState.Error)
                }
            }
            return false;
        }
    }
})