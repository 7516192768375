sap.ui.define([
    "univar/tcard/controller/BaseController",
	"sap/m/MessageBox",
	"sap/ui/model/json/JSONModel",
	"univar/tcard/src/security/authenticate",
	"univar/tcard/src/api-comm/post-update-password",
	'sap/m/MessageToast',
	"../src/utilities/busyStack"
], function(BaseController, MessageBox, JSONModel, Authentication, Update, Toast) {
	"use strict";

	return BaseController.extend("univar.tcard.controller.ResetPassword", {
		
		_onButtonPress: function(oEvent) {

            oEvent = jQuery.extend(true, {}, oEvent);
            
            var model = this.getView().getModel();
			var controller = sap.ui.getCore().controller;
			var view = controller.getView()

			var item = model.oData

			if(item.pass && item.conf && item.pass===item.conf && item.validPw){

				//view.setBusy(true)
				var setNotBusy=this.getBusyStack().addBusy(view)

				Update.handleAsync(model.oData.pass)
				.then(success=>{
					if(success){
						setNotBusy()
						Toast.show("Password updated.")
						$( ".sapMMessageToast" ).addClass( "sapMMessageToastSuccess " );
						setTimeout(() => { 
							this.logout()
						}, 2000)
					}
				})
				.catch((error)=>{
					console.log(error)
					Toast.show("Failed to update password.")
					$( ".sapMMessageToast" ).addClass( "sapMMessageToastDanger " );
					//view.setBusy(false)
					setNotBusy()
				})
			}
			else{
				Toast.show("New password does not meet the minimum requirements.")
				$( ".sapMMessageToast" ).addClass( "sapMMessageToastWarning " );
			}
		},
		onInit: function(oEvent) {
            var oRouter = this.getRouter();
			oRouter.getRoute("resetpassword").attachMatched(this._onRouteMatched, this);
			this._oninit(oEvent)
		},		
		_onRouteMatched : async function (oEvent) {
			var oView = this.getView();
			//oView.setBusy(true);
			var setNotBusy=this.getBusyStack().addBusy(oView)
			var btn = oView.byId('submit')

			var oModel = new JSONModel({
				pass:"",
				conf:"",
				valid:false
			})
			oView.setModel(oModel)

			var oArgs = oEvent.getParameter("arguments");
			if(oArgs){
				var sValue = oArgs.token
				if(sValue){
					var success = await Authentication.login({reset:sValue})
					.catch((error)=>{
						setNotBusy()
						console.error(error)
					})
					if(success){
						//oView.setBusy(false);
						setNotBusy()
						return;
					}
				}
				else{
					setNotBusy()
				}
			}
			else{
				setNotBusy()
			}

			//oView.setBusy(false);
			this.getRouter().getTargets().display("notFound", {
				fromTarget : "home"
			});
		}
	});
}, /* bExport= */ true);
