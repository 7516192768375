

sap.ui.define([
    "univar/tcard/controller/BaseController",
	"sap/ui/core/UIComponent",
	"sap/ui/model/json/JSONModel",
	"univar/tcard/img/Logo.png",
	"sap/m/MenuItem",
	"univar/tcard/src/utilities/busyStack",
], function(BaseController, UIComponent, JSONModel, favicon, MenuItem,BusyStack) {
	"use strict";

	var thisContext;

	return BaseController.extend("univar.tcard.controller.App", {

		onInit: function() {
			thisContext = this
			sap.ui.getCore().AppContext = thisContext
			sap.ui.getCore().busyStack = new BusyStack()
			var view = this.getView()
			this.placeFavicon(view)
			
		},

		getShellBar : function(){
			var id = thisContext.createId('shellBar')
			return thisContext.byId(id)
		},

		removeAllMenuItems : function(){
			var menu = thisContext.getShellBar().getMenu()
			menu.destroyItems();
		},
		setMenuItems: function(items){
			thisContext.removeAllMenuItems()
			var menu = thisContext.getShellBar().getMenu()
			items.map((options,index)=>{
				var item = new MenuItem('menuItem'+index, {text: options.text, startsSection:options.startsSection})
				item.attachPress(options.onPress)
				menu.addItem(item)
			})
		},
		homeIconPressed : async function(oEvent){
			var isLoggedIn = await thisContext.isLoggedIn()
			if(isLoggedIn){
				thisContext.navigate("companies")
			}else{
				thisContext.navigate('login')
			}
		},
		placeFavicon : function(view){
			view = view || this.getView()
			if(!view) return
			var shellBar = view.byId('shellBar')
			if(!shellBar) return
			shellBar.setHomeIcon(favicon.default)
			shellBar.setHomeIconTooltip("Home");
			shellBar.attachHomeIconPressed(this.homeIconPressed)

		},
		navToLogin: function(){
			thisContext.navigate('login')
		},
		navToSignUp: function(){
			thisContext.navigate('signup')
		}

	});

});
