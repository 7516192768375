sap.ui.define([
    "../exceptions/UnauthorisedException",
    "../security/authenticate"
], function(UnauthException, Authentication) {
    return {
        get : async function(){
            
            var response = await fetch(`${appconfig.host}/settings/PasswordPolicy`,{
                method: 'GET',
                mode: "cors"
            })
            .catch((error)=>{
                console.log(error)
                throw new UnauthException("Fetch Failed", error)
            })

            if(response.status===200){
                return await response.json();
            }            
        },
        post : async function({MinLengthWeak, MinLengthStrong, MinLowerCase,MinLowerCaseEnforced, MinUpperCase, MinUpperCaseEnforced, MinNumeric, MinNumericEnforced, MinSpecial, MinSpecialEnforced, Special}){
            
            var token = await Authentication.getToken()
            var response = await fetch(`${appconfig.host}/settings/PasswordPolicy`,{
                method: 'POST',
                mode: "cors",
                headers:{
                    "Authorization":`Bearer ${token.accessToken}`,
                    "Content-Type":"application/json"
                },
                body: JSON.stringify({
                        MinLengthWeak, 
                        MinLengthStrong, 
                        MinLowerCase, 
                        MinLowerCaseEnforced, 
                        MinUpperCase, 
                        MinUpperCaseEnforced, 
                        MinNumeric, 
                        MinNumericEnforced, 
                        MinSpecial, 
                        MinSpecialEnforced, 
                        Special
                    })
            })
            .catch((error)=>{
                console.log(error)
                throw new UnauthException("Fetch Failed", error)
            })

            if(response.status===200){
                return {success:true, new: false}
            }  
            if(response.status===201){
                return {success:true, new: true}
            }  
            return {success: false}
        }
    }
})