sap.ui.define([
    "../exceptions/UnauthorisedException",
    "../security/authenticate"
], function(UnauthException, Authentication) {
    return {
        handleGetAsync : async function(customerId){
            
            var token = await Authentication.getToken()
            var response = await fetch(`${appconfig.host}/user/basket/${customerId}`,{
                method: 'GET',
                mode: "cors",
                headers:{
                    "Authorization":`Bearer ${token.accessToken}`
                }
            })
            .catch((error)=>{
                console.log(error)
                throw new UnauthException("Fetch Failed", error)
            })

            if(response.status===200){
                return await response.json();
            }            
        },
        handlePostAsync : async function(customerId, {itemId, unitOfMeasureId, quantity}){
            
            var token = await Authentication.getToken()
            var response = await fetch(`${appconfig.host}/user/basket/${customerId}`,{
                method: 'POST',
                mode: "cors",
                headers:{
                    "Authorization":`Bearer ${token.accessToken}`,
                    "Content-Type":"application/json"
                },
                body: JSON.stringify({
                    CustomerId:customerId,
                    ItemId:itemId, 
                    UnitOfMeasureId:unitOfMeasureId, 
                    Quantity:quantity})
            })
            .catch((error)=>{
                console.log(error)
                throw new UnauthException("Fetch Failed", error)
            })

            if(response.status===200){
                return {success:true, new: false}
            }  
            if(response.status===201){
                return {success:true, new: true}
            }  
            return {success: false}
        },
        handleDeleteAsync : async function(basketId){
            
            var token = await Authentication.getToken()
            var response = await fetch(`${appconfig.host}/user/basket/${basketId}`,{
                method: 'DELETE',
                mode: "cors",
                headers:{
                    "Authorization":`Bearer ${token.accessToken}`,
                    "Content-Type":"application/json"
                }
            })
            .catch((error)=>{
                console.log(error)
                throw new UnauthException("Fetch Failed", error)
            })

            if(response.status===200){
                return {success:true, new: false}
            }  
            return {success: false}
        },
        handlePatchAsync : async function(basketId, quantity){
            
            var token = await Authentication.getToken()
            var response = await fetch(`${appconfig.host}/user/basket/${basketId}`,{
                method: 'PATCH',
                mode: "cors",
                headers:{
                    "Authorization":`Bearer ${token.accessToken}`,
                    "Content-Type":"application/json"
                },
                body: JSON.stringify({
                    Quantity:quantity
                })
            })
            .catch((error)=>{
                console.log(error)
                throw new UnauthException("Fetch Failed", error)
            })

            if(response.status===200){
                return {success:true, new: false}
            }  
            return {success: false}
        },
        handlePutToOrderAsync : async function(customerId, poNumber){
            
            var body = JSON.stringify({poNumber});

            var token = await Authentication.getToken()
            var response = await fetch(`${appconfig.host}/user/basket/${customerId}`,{
                method: 'PUT',
                mode: "cors",
                headers:{
                    "Authorization":`Bearer ${token.accessToken}`,
                    "Content-Type":"application/json"
                },
                body
            })
            .catch((error)=>{
                console.log(error)
                throw new UnauthException("Fetch Failed", error)
            })

            if(response.status===200){
                return {success:true, new: false}
            }  
            return {success: false}
        }
    }
})