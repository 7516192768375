sap.ui.define([
    "../exceptions/UnauthorisedException",
], function(UnauthException) {
    return {
        handleAsync : async function(email){
            var body = JSON.stringify({email:email})
            var response = await fetch(`${appconfig.host}/user/recoverpassword`,{
                method: 'POST',
                mode: "cors",
                body: body,
                headers:{
                    "content-type":"application/json"
                },
            })         
            .catch((error)=>{
                console.log(error)
                throw new UnauthException("Request Failed", error)
            })

            if(response.status===200){
                return true;
            }
            return false;
        }
    }
})