sap.ui.define([
    "univar/tcard/controller/BaseController",
	'sap/ui/core/Fragment',
	"sap/m/MessageBox",
	"sap/ui/core/routing/History",
	"sap/ui/model/json/JSONModel",
	'sap/m/MessageToast',
    "univar/tcard/src/api-comm/oData/get-odata-service"
], function(BaseController, Fragment, MessageBox, History, JSONModel, Toast,oDataService) {
    "use strict";
    
	return BaseController.extend("univar.tcard.controller.Admin.uom-admin-split", {
        
        onItemPress: function(oEvent){

			var oItem = oEvent.getParameters().listItem;
			var oCtx = oItem.getBindingContext();
			var path = oCtx.getPath();
			//this.getView().byId("detail").bindElement({path, parameters:{$expand:"UnitOfMeasureItems"}});
        },
		onInit: function() {
			var oRouter = this.getRouter();
			oRouter.getRoute("unitsofmeasure").attachMatched(this.onRouteMatched, this);
		},
		onRouteMatched : function(oEvent){
			
            oDataService.then(oModel=>{

                var model = sap.ui.getCore().getModel('uom_search') || new sap.ui.model.json.JSONModel();

                if(sap.ui.getCore().getModel('uom_search')){
                    this.onSearch(oEvent)
                }

                model.oData.text = model.oData.text || "";
                this.getView().setModel(model,'uom_search');
                this.getView().setModel(oModel);
                
                oModel.getMetaModel().requestObject("/UnitsOfMeasure/").then(res=>{
                    var oLastModified = oModel.getMetaModel().getLastModified();
                    console.log("Last-Modified: " + oLastModified,
					oLastModified && oLastModified.toISOString(),
                    "univar.controllers.UOMAdmin.uom-admin-split");
                    
                }).catch(err=>console.log(err))

            })
        }
    })
})