sap.ui.define([
    "univar/tcard/controller/BaseController",
	"sap/m/MessageBox",
	"sap/ui/core/routing/History"
], function(BaseController, MessageBox, History) {
	"use strict";

	return BaseController.extend("univar.tcard.controller.SignUpConfirmation", {
		_onButtonPress: function(oEvent) {
			this.getRouter().navTo("login")
		},
		onInit: function() {
            var oRouter = this.getRouter();
			oRouter.getRoute("SignUpConfirmation").attachMatched(this._onRouteMatched, this);
		},
		_onRouteMatched : async function (oEvent) {

		}
	});
}, /* bExport= */ true);
