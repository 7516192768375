
sap.ui.define([
    "univar/tcard/controller/BaseController",
	"sap/m/MessageBox",
	"../src/security/authenticate",
	"../src/api-comm/get-my-details",
	"../src/utilities/busyStack"
], function(BaseController, MessageBox, Authentication, getUserDetails, busyStack) {
	"use strict";

	return BaseController.extend("univar.tcard.controller.Login", {

		_onRecoverPress : async function(){
			this.navigate("passwordrecovery", false); 
		},
		_onSignupPress : async function(){
			this.navigate("signup", false); 
		},
		_onLoginPress : async function(){
			var view = this.getView()
			//view.setBusy(true)
			var setNotBusy=this.getBusyStack().addBusy(view)
			var loginDetails = view.getModel("loginDetails").oData
			if(!loginDetails || !loginDetails.hasOwnProperty("username") || !loginDetails.hasOwnProperty("password") ||!loginDetails.username || !loginDetails.password ){
				//throw error somehow?
				alert("Please enter your login details")
				//view.setBusy(false)
				setNotBusy()
			}
			else{
				var success = await Authentication.login({u:loginDetails.username, p:loginDetails.password})
				.catch(function(exception){	
					MessageBox.error(
						"Please contact your administrator.", {
							title: "Login Failed",
							actions: [MessageBox.Action.OK],
							onClose: function(oAction) { 
								setNotBusy()
							}
						}
					);
				})

				if(success){

					var userDetails = await getUserDetails.handleAsync()
					.catch(err=>{
						console.log(err)
						this.showToast("There is an issue with your login.", "Danger")
					})
					if(userDetails){
						 this.getRouter().navTo("companies", false);   
					}
					setNotBusy()
				}
			}
		},
		onInit: function() {
			this._oninit()
			var oRouter = this.getRouter();
			oRouter.getRoute("login").attachMatched(this._onRouteMatched, this);
		},
		
		_onRouteMatched : function (oEvent) {
			
			this.isLoggedIn().then((result)=>{

				if(result){
					this.getRouter().navTo("companies");
				}else{
						
					var loginModel = new sap.ui.model.json.JSONModel({
						username:"",
						password:""
					})
					this.getView().setModel(loginModel,"loginDetails")
				}
			}).catch((error)=>{
				
				var loginModel = new sap.ui.model.json.JSONModel({
					username:"",
					password:""
				})
				this.getView().setModel(loginModel,"loginDetails")
			})

		
		}

	}
	);
}, /* bExport= */ true);
