
sap.ui.define([],function(){
    return class Exception {

        constructor(message, innerException){
            this.message = message;
            this.innerException = innerException;                
        }
        logToConsole (){
            console.log.apply(console, typeof(this), message)
            (
                this.innerException && 
                this.innerException instanceof Exception && 
                (this.innerException.logToConsole)
            )
        }
    }
})
