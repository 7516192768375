sap.ui.define([
    "univar/tcard/controller/BaseController",
	'sap/ui/core/Fragment',
	"sap/ui/model/json/JSONModel",
    "univar/tcard/src/api-comm/oData/get-odata-service",
    "univar/tcard/src/api-comm/contact-customer/index",
    "sap/ui/model/Filter",
    "sap/ui/model/FilterOperator",
	"sap/ui/model/FilterType",
	"univar/tcard/src/api-comm/post-sign-up",
	"univar/tcard/src/api-comm/get-checkusername",
    "univar/tcard/src/utilities/busyStack"
], function(BaseController, Fragment, JSONModel, oDataService, ContactCustomer, Filter,Operator,FilterType, SignUp,UCheck) {
    "use strict";
    var typingTimer;                //timer identifier
	var doneTypingInterval = 500;  //time in ms
	var usernameCtrl;

	
	const doneTyping = function () {
		if(usernameCtrl){
            usernameCtrl.setValueState(sap.ui.core.ValueState.None)
            var username = usernameCtrl.getValue()
            if(username){
                UCheck.handleAsync(username).then((c)=>{
                    if(c){
                        usernameCtrl.setValueState(sap.ui.core.ValueState.Success)
                    }else{
                        usernameCtrl.setValueState(sap.ui.core.ValueState.Error)
                    }
                }).catch((error)=>{})
            }
        }
	}
	return BaseController.extend("univar.tcard.controller.Admin.contact-admin-split", {
        
        onItemPress: function(oEvent){

			var oItem = oEvent.getParameters().listItem;
			var path = oItem.getBindingContextPath();
			this.getView().byId("detail").bindElement(`users>${path}`);
        },
        refreshModel : function(){
            this.getView().getModel('users').refresh()
        },
		onInit: function() {
			var oRouter = this.getRouter();
			oRouter.getRoute("contacts").attachMatched(this.onRouteMatched, this);
        },
        onUpdateFinished : function(oEvent){
            var oList = oEvent.getSource();
            var aItems = oList.getItems();
            if(aItems && aItems.length){
                var oItem = aItems[0]
                oList.setSelectedItem(oItem)
                var path = oItem.getBindingContextPath(); 
                this.getView().byId("detail").bindElement(`users>${path}`);
                //this.getView().byId("detail2").bindElement(path);
            }
        },
        removeCustomersPressed : async function(oEvent){

            var view = this.getView()
            var table = view.byId("CompanyAssignmentTable");
            var oModel = view.getModel('users');
            //var url = oModel.sServiceUrl
            var rowItems = table.getSelectedItems();

            var promises = []

            rowItems.forEach(row=>{
                //row.getBindingContext().delete()

                var boundObject = row.getBindingContext('users') //"/ContactViews(cb04fd07-f233-489d-a9ee-dc3091de899e)/Customers/197"
                var customerId = boundObject.getProperty("CustomerId")
                var contactId = boundObject.getProperty("ContactId")
                
                promises.push(ContactCustomer.delete.handleAsync({contactId, customerId}))

                
            })

            if(promises && promises.length){
                Promise.all(promises)
                .then(results=>{
                    oModel.refresh()
                    table.removeSelections(true)
                })
            }

        },
        viewAssignedCustomers: function(){
            this.getSplitContentObj().toDetail(this.createId('detail'))
        },
        unassignedListFilter: function(oEvent){
            var query = oEvent.getParameter("query")
            var customers = this.NewCustomers.filter(c=>c.CardCode.includes(query))
            this.getView().setModel(new JSONModel(customers), "NewCustomers")
        },
        NewCustomers :[],
        viewUnassignedCustomers: async function(){
            var view = this.getView();
            //var table = view.byId("CompanyAssignmentTable");
            // var id = ''
             var details2 = view.byId('detail2')
             var details1 = view.byId('detail')
             var contactId = details1.getBindingInfo('title').binding.oContext.getObject().Id
             //details2.setBusy(true)
             this.getSplitContentObj().toDetail(this.createId('detail2'))
             var filterModel = view.getModel('newCustomer_search')

             var params = {}

             var cardcode = filterModel.oData.CardCode
             var name = filterModel.oData.Name 
             var pricelist = filterModel.oData.PriceList 
             var warehouse = filterModel.oData.Warehouse 

             !!cardcode && (params.cardcode = cardcode)
             !!name && (params.name = name)
             !!pricelist && (params.pricelist = pricelist)
             !!warehouse && (params.warehouse = warehouse)

            var customers = await ContactCustomer.new(contactId, params)
            .catch(err=>{
                //details2.setBusy(false)
                setNotBusy()
                console.log(err)
                this.showToast("Error downloading customer list", "Danger")
            })

            if(customers){
                this.NewCustomers = customers.sort((a,b)=>{
                    if(a.CardCode < b.CardCode) return -1
                    if(a.CardCode > b.CardCode) return 1
                    return 0
                })
                view.setModel(new JSONModel(this.NewCustomers), "NewCustomers")

                // view.byId('NewCompanyTable').bindRows("/")
            }
            //details2.setBusy(false)
            setNotBusy()
            // table.getBindingContext().getObject()
            // var unassigned = await ContactCustomer.GetUnassigned(id)

            // var model = new JSONModel(unassigned)
            // view.setModel(model,'NewCustomers')
        },
        
		getSplitContentObj: function(){
			var result = this.byId("contactList");
			return result;
		},
        addCustomersPressed : function(oEvent){
            
            var view = this.getView()
            var table = view.byId("NewCustomerTable");
            var oModel = view.getModel('NewCustomers');
            var uModel = view.getModel('users');
            //var url = oModel.sServiceUrl
            var rowItems = table.getSelectedItems();

            var promises = []

            var details1 = view.byId('detail')
            var contactId = details1.getBindingInfo('title').binding.oContext.getObject().Id

            rowItems.forEach(row=>{
                //row.getBindingContext().delete()

                var path = row.getBindingContextPath() //"/197"
                var boundObject = oModel.getProperty(path)
                var customerId = boundObject.CustomerId
                
                promises.push(ContactCustomer.add.handleAsync(contactId, customerId))

                
            })

            if(promises && promises.length){
                Promise.all(promises)
                .then(results=>{
                    uModel.refresh()
                    this.viewAssignedCustomers()
                })
            }
        },
        customerAddSelectionChange : function(oEvent){
            var selectedItems = oEvent.getSource().getSelectedItems()
            
            var props = this.getView().getModel('customerProps')

            props.setProperty('/hasAddSelected',!!(selectedItems && selectedItems.length))
            
        },
        customerDelSelectionChange: function(oEvent){
            var selectedItems = oEvent.getSource().getSelectedItems()
            
            var props = this.getView().getModel('customerProps')

            props.setProperty('/hasDelSelected',!!(selectedItems && selectedItems.length))
            
        },
        search : function(oEvent){
            var query = oEvent.getParameter('query')
            if(query){
                var filter = new Filter({path:"Name", operator: Operator.Contains, value1:query})
            }
            
            var logTable = this.getView().byId('contact-master-list')
            logTable.getBinding("items").filter(filter, FilterType.Application);
        },
        onSearch2 : function(oEvent){

        },
		onRouteMatched : function(oEvent){
            
            this.isLoggedIn().then(result=>{

                if (result){
                    oDataService.then(oModel=>{
                        var view = this.getView()
                        var model = sap.ui.getCore().getModel('user_search') || new sap.ui.model.json.JSONModel({test:''});
                        var newCustomerSearchModel = sap.ui.getCore().getModel('newCustomer_search') || new sap.ui.model.json.JSONModel({
                            CardCode:null,
                            Name:null,
                            PriceList:null,
                            Warehouse:null
                        });

                        view.setModel(model,'user_search');
                        view.setModel(newCustomerSearchModel,'newCustomer_search');

                        if(sap.ui.getCore().getModel('user_search')){
                            this.onSearch(oEvent)
                        }

                        if(sap.ui.getCore().getModel('user_search')){
                            this.onNewCustomerSearch(newCustomer_search)
                        }

                        view.setModel(oModel,'users');

                        var customersSelected = new sap.ui.model.json.JSONModel({hasDelSelected:false,hasAddSelected:false});
                        view.setModel(customersSelected,'customerProps');
                        
                        oModel.getMetaModel().requestObject("/Contacts/").then(res=>{
                            var oLastModified = oModel.getMetaModel().getLastModified();
                            console.log("Last-Modified: " + oLastModified,
                            oLastModified && oLastModified.toISOString(),
                            "univar.tcard.controller.Admin.contact-admin-split");
                            
                        }).catch(err=>console.log(err))
                        
                    })
                }else{
                    this.navigate('login')
                    this.showToast('login error','Danger')
                }
            }).catch(err=>{
                console.log(err)
                this.showToast(err,'Danger')
            })
        },
        openAddUser : async function(){
			if(sap.ui.getCore().controller._userProfile){
				//sap.ui.getCore().controller._userProfile.setBusy(false)
                setNotBusy()
                sap.ui.getCore().controller._userProfile.destroy()
                sap.ui.getCore().controller._userProfile = undefined
            }
            
            if(!this._newUserFrag){

                var dialog = await Fragment.load({
                    name:'univar.tcard.view.Admin.newUser',
                    controller: this
                })
                
                this._newUserFrag = dialog
            }
			
            var newUser = new JSONModel({
				firstName : "",
				lastName : "",
				email : "",
				tel : "",
                username : "",
                password: ""
            });
            			
			this._newUserFrag.setModel(newUser)
            this._newUserFrag.open()		
			
        },
        onNewUserDialogClose: function(oEvent){
            var button = oEvent.getSource()
            var controller = this;
            //var newUserDialog = controller._newUserFrag;

			var close = function(){
				//controller._newUserFrag.setBusy(false)
                setNotBusy()
				controller._newUserFrag.destroy()
                controller._newUserFrag = undefined
            }
            var model = controller._newUserFrag.getModel().oData;
            if(button.sId === "ok"){
                if(!model.firstName
                    || !model.lastName
                    || !model.email
                    || !model.tel
                    || !model.username
                    || !model.password){
                    this.showToast("Form incomplete", "Danger")
                }else{
                    //controller._newUserFrag.setBusy(true);
                    var setNotBusy=this.getBusyStack().addBusy(controller._newUserFrag)

                    SignUp.handleAsync(model.firstName,model.lastName,model.email,model.tel,model.password,model.username)
                    .then(response=>{
                        if(response.successful){
                            close();
                            this.refreshModel()
                            this.showToast("User added successfully", "Success")
                        }else {
                            //controller._newUserFrag.setBusy(false)
                            setNotBusy()
                            this.showToast(response.message, "Danger")                            
                        }
                    })
                    .catch((error)=>{
                        var message = "An error occurred";
                        if(error.hasOwnProperty("Message")){
                            message = error.Message;
                        }else if(typeof error === "string"){
                            message = error;
                        }
                        this.showToast(message, "Danger")
                        //controller._newUserFrag.setBusy(false)
                        setNotBusy()
                    })
                }
            }else{
                close()
            }
        },
		_onUsernameChange : function(oEvent){
			clearTimeout(typingTimer);
			usernameCtrl = oEvent.getSource()
            usernameCtrl.setValueState(sap.ui.core.ValueState.None)            
			typingTimer = setTimeout(doneTyping, doneTypingInterval);
		},
        adminAttributeFormatter:function(isAdmin){
            if(isAdmin && isAdmin === "Yes"){
                return "Admin"
            }
            return " ";
        },
        highlightStatusFormatter:function(active){
            if(active==="No"){
                return "Error";
            }
            return "None"
        }
    })
})