
      sap.ui.requireSync("sap/f/ShellBar");
sap.ui.requireSync("sap/m/Menu");
sap.ui.requireSync("sap/m/MenuItem");
sap.ui.requireSync("sap/m/App");
sap.ui.requireSync("univar/tcard/controller/App.controller");

      var parser = new DOMParser();
      var xml = parser.parseFromString("<mvc:View\r\n\tcontrollerName=\"univar.tcard.controller.App\"\r\n\txmlns=\"sap.m\"\r\n\txmlns:f=\"sap.f\"\r\n\txmlns:mvc=\"sap.ui.core.mvc\"\r\n\tdisplayBlock=\"true\">\r\n\r\n\t\t<f:ShellBar id=\"shellBar\"\r\n\t\t\t\ttitle=\"{i18n>TITLE}\"\r\n\t\t\t\tshowMenuButton=\"false\">\r\n\t\t\t\t<!-- title=\"{i18n>TITLE}\"\r\n\t\t\t\thomeIcon=\"./resources/sap/ui/documentation/sdk/images/logo_ui5.png\"\r\n\t\t\t\tsecondTitle=\"Short description\"\r\n\t\t\t\tshowCopilot=\"true\"\r\n\t\t\t\tshowSearch=\"true\"\r\n\t\t\t\tshowNotifications=\"true\"\r\n\t\t\t\tshowProductSwitcher=\"true\"\r\n\t\t\t\tnotificationsNumber=\"2\"> -->\r\n\t\t\t<f:menu id='shellMenu'>\r\n\t\t\t\t<Menu>\r\n\t\t\t\t\t<MenuItem text=\"Login\" icon=\"sap-icon://arrow-right\" press=\".navToLogin\"/>\r\n\t\t\t\t\t<MenuItem text=\"Sign Up\" icon=\"sap-icon://add\" press=\".navToSignUp\"/>\r\n\t\t\t\t</Menu>\r\n\t\t\t</f:menu>\r\n\t\t</f:ShellBar>\r\n\t\t<App id=\"appShell\"/>\r\n\r\n</mvc:View>", "text/xml");
      module.exports = xml;
    