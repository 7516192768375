sap.ui.define([  
    "univar/tcard/controller/BaseController",
    "univar/tcard/src/api-comm/oData/get-system-logs",
    "sap/ui/model/Filter",
    "sap/ui/model/FilterOperator",
	"sap/ui/model/FilterType"
  ], function(BaseController,oDataLogs, Filter,Operator,FilterType) {  
    "use strict";  
    return BaseController.extend("univar.tcard.controller.Admin.systemlogs", {  
      

        _onRouteMatched : function(oEvent){

            this.isLoggedIn().then(result=>{

                if (result){

                    oDataLogs.then(oModel=>{

                        var model = sap.ui.getCore().getModel('log_search') || new sap.ui.model.json.JSONModel();

                        if(sap.ui.getCore().getModel('log_search')){
                            this.onSearch(oEvent)
                        }

                        model.oData.text = model.oData.text || "";
                        model.oData.from = model.oData.from || "";
                        model.oData.to = model.oData.to || "";
                        this.getView().setModel(model,'log_search');
                        this.getView().setModel(oModel,'SystemLogs');
                        
                        // var logTable = this.getView().byId('log_table');
                        // logTable.setModel(oModel);
                        // var oTemplate = new sap.m.ColumnListItem({
                        //     cells: [new sap.m.Text({
                        //         text: "{Type}"
                        //     }), new sap.m.Text({
                        //         text: "{SessionId}"
                        //     }), new sap.m.Text({
                        //         text: {path:'DateOccurred', formatter:'.dateFormatter'}
                        //     })]
                        // });
                        // logTable.bindAggregation("items", {
                        //     path: "/SystemLogsViews",
                        //     parameters:{$orderby:'DateOccurred desc'},
                        //     template: oTemplate
                        // })
                    })
                }else{
                    this.navigate('login')
                    this.showToast('login error','Danger')
                }
            }).catch(err=>{
                console.log(err)
                this.navigate('login')
                this.showToast(err,'Danger')
            })
        },
        onInit: function(){
			var oRouter = this.getRouter();
			oRouter.getRoute("systemlogs").attachMatched(this._onRouteMatched, this);
			this._oninit()
        },
        onSearch: function(oEvent){
            var model = this.getView().getModel('log_search')
            sap.ui.getCore().setModel(model, 'log_search')

            var filters = [];

            console.log(model.oData.from)
            console.log(new Date(model.oData.from))

             if(model.oData.from){
                var fromDate = new Filter({path:"DateOccurred", operator: Operator.GE, value1:new Date(model.oData.from).toISOString()})
                filters.push(fromDate)
            } if(model.oData.to){
                var toDate = new Filter({path:"DateOccurred", operator: Operator.LE, value1:new Date(model.oData.to).toISOString()})
                filters.push(toDate)
            }
            if(model.oData.text){
                var textDescription = new Filter({path:"Type", operator: Operator.Contains, value1:model.oData.text})
                filters.push(textDescription)
            }

            var oFilter = new Array(new Filter({filters:filters, and:true}))

            var logTable = this.getView().byId('log_table')
            var bindings = logTable.getBinding("items")
            if(bindings){
                bindings.filter(oFilter, FilterType.Application);
            }
        },
        onChange: function(oEvent){
            var model = this.getView().getModel()            
        },
        countFormatter: function(count){
            return `(${count})`
        },
        clearFilter: function(oEvent){

            var reset = new sap.ui.model.json.JSONModel({
                text:'',
                from:'',
                to:''
            })

            this.getView().setModel(reset,'log_search')

            sap.ui.getCore().setModel(reset, 'log_search')
        }
    });  
  });  