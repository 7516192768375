sap.ui.define([
    "../exceptions/UnauthorisedException"
], function(UnauthException) {
    return {
        handleAsync : async function(username){
            var response = await fetch(`${appconfig.host}/user/checkusername/${username}`,{
                method: 'GET',
                mode: "cors",
            })         
            .catch((error)=>{
                console.log(error)
                throw new UnauthException("Fetch Failed", error)
            })

            if(response.status===200){
                return true;
            }
            return false;
        }
    }
})