sap.ui.define([
    "univar/tcard/controller/BaseController",
	"univar/tcard/src/api-comm/get-my-companies",
    "univar/tcard/src/exceptions/UnauthorisedException",
	"univar/tcard/src/utilities/busyStack"
], function(BaseController, GetMyCompanies,UnauthorisedException) {
	"use strict";
    const getIndexFrompath = function(path){
        var sub = path.replace('\/', '')
        var parsed = parseInt(sub, 10);
        if (isNaN(parsed)) {
             return 0 
        }
        return parsed
    }
    var companies = []
	return BaseController.extend("univar.tcard.controller.Companies.company-selection", {   
             
		_onRouteMatched : function(oEvent){
            
            var router = this.getRouter()

            var view = this.getView()
            if(!view.hasStyleClass('wide-list'))
            view.addStyleClass('wide-list')
            var list = this.byId("companyList");
            //list.setBusy(true)
            var setNotBusy=this.getBusyStack().addBusy(list)

            this.isLoggedIn().then((result)=>{

                if(result){
                    GetMyCompanies.handleAsync().then(function(result){
                        companies = result;
                        var oModel = new sap.ui.model.json.JSONModel(companies)
                        view.setModel(oModel);
                        //list.setBusy(false)
                        setNotBusy()
                    }).catch(function(error){
                        if(error.__proto__.constructor === UnauthorisedException){
                            router.navTo("login");
                        }
                        //list.setBusy(false)
                        setNotBusy()
                        console.log(error)
                    })
                }else{
                    router.navTo("login");
                    //list.setBusy(false)
                    setNotBusy()
                }
            }).catch((error)=>{
                router.navTo("login");
                //list.setBusy(false)
                setNotBusy()
            })

		},
        onInit: function(){
			var oRouter = this.getRouter();
			oRouter.getRoute("companies").attachMatched(this._onRouteMatched, this);
			this._oninit()
        },
        onSearch : function(oEvt){
			var sQuery = oEvt.getSource().getValue();
            var oModel = new sap.ui.model.json.JSONModel(companies.filter(c=>c.AreaName.toLowerCase().includes(sQuery.toLowerCase())))
            this.getView().setModel(oModel)
        },
        onListPress : function(oEvt){
            var view = this.getView()
            var path = oEvt.getParameters().listItem.getBindingContext().getPath()
            var index = getIndexFrompath(path)
            var model = view.getModel()
            var company = model.oData[index]
            console.log(company)

            
			this.getRouter().navTo("company",{
				companyId : company.Id
			});
        },
        onItemPress : function(oEvent){
            /*
                Doesn't fire >_<
            */

			// var oItem, oCtx;

			// oItem = oEvent.getSource();
			// oCtx = oItem.getBindingContext();

			// this.getRouter().navTo("company",{
			// 	employeeId : oCtx.getProperty("Id")
			// });
        }
    })
})