sap.ui.define([
    "univar/tcard/controller/BaseController",
	"sap/ui/model/json/JSONModel",
	"univar/tcard/src/utilities/validator",
	"univar/tcard/src/api-comm/post-sign-up",
	"univar/tcard/src/api-comm/get-checkusername",
	'sap/m/MessageToast',
	"../src/utilities/busyStack"
], function(BaseController, JSONModel, Validator, Post, UCheck, Toast) {
	"use strict";
	var typingTimer;                //timer identifier
	var doneTypingInterval = 500;  //time in ms
	var thisView;

	
	const doneTyping = function () {
			
		var ctrl = thisView.byId("username");
		ctrl.setValueState(sap.ui.core.ValueState.None)
		var username = ctrl.mProperties.value
		if(username){
			UCheck.handleAsync(username).then((c)=>{
				if(c){
					ctrl.setValueState(sap.ui.core.ValueState.Success)
				}else{
					ctrl.setValueState(sap.ui.core.ValueState.Error)
				}
			}).catch((error)=>{})
		}
	}
	return BaseController.extend("univar.tcard.controller.SignUp", {
		
		formValid : function(){
			var view = this.getView()
			var oModel = view.getModel().oData
			
			var firstName = view.byId("firstname");
			var lastName = view.byId("lastname");
			var username = view.byId("username");
			var email = view.byId("email");
			var pass = view.byId("pass");
			var conf = view.byId("conf");
			var tel = view.byId("tel");
			if (oModel.firstName!==$.find(`#${firstName.sId}-inner`)[0].value){
				oModel.firstName = $.find(`#${firstName.sId}-inner`)[0].value
			}
			if (oModel.lastName!==$.find(`#${lastName.sId}-inner`)[0].value){
				oModel.lastName = $.find(`#${lastName.sId}-inner`)[0].value
			}
			if (oModel.email!==$.find(`#${email.sId}-inner`)[0].value){
				oModel.email = $.find(`#${email.sId}-inner`)[0].value
			}
			if (oModel.username!==$.find(`#${email.sId}-inner`)[0].value){
				oModel.username = $.find(`#${email.sId}-inner`)[0].value
			}
			if (oModel.tel!==$.find(`#${tel.sId}-inner`)[0].value){
				oModel.tel = $.find(`#${tel.sId}-inner`)[0].value
			}
			if (oModel.username!==$.find(`#${username.sId}-inner`)[0].value){
				oModel.username = $.find(`#${username.sId}-inner`)[0].value
			}
			if (oModel.pass!==$.find(`#${pass.sId}-inner`)[0].value){
				oModel.pass = $.find(`#${pass.sId}-inner`)[0].value
			}
			if (oModel.conf!==$.find(`#${conf.sId}-inner`)[0].value){
				oModel.conf = $.find(`#${conf.sId}-inner`)[0].value
			}

			view.setModel(new JSONModel(oModel))

			return (!!oModel.firstName && !!oModel.lastName && !!oModel.email 
				&& !!Validator.email(oModel.email) && !!oModel.tel 
				&& !!oModel.tandc && !!oModel.username && !!oModel.validPw)
				

		},
		_onUsernameChange : function(oEvent){
			clearTimeout(typingTimer);
			var ctrl = this.getView().byId("username");
			ctrl.setValueState(sap.ui.core.ValueState.None)
			this._onChange(oEvent);
			typingTimer = setTimeout(doneTyping, doneTypingInterval);
		},
		_onChange : function(oEvent){
			this.formValid()
			var view = this.getView()
			var oModel = view.getModel().oData
			oModel.tandc = false
			view.setModel(new JSONModel(oModel))
			view.byId('submit').setEnabled(this.formValid())
		},
		_onTandC : function(oEvent){
			var view = this.getView()
			view.byId('submit').setEnabled(this.formValid())
		},
		_onButtonPress: async function(oEvent) {
			
			var view = this.getView()
			//view.setBusy(true)
			var setNotBusy=this.getBusyStack().addBusy(view)

			if(this.formValid()){
				
				var view = this.getView()
				var oModel = view.getModel().oData
			
				var response = await Post.handleAsync(oModel.firstName,oModel.lastName,oModel.email,oModel.tel,oModel.pass,oModel.username)
				.catch((error)=>{
					console.log(error)
					if(!response){
						response = {}
					}
					if(!response.message){
						response.message = error.message || error.ReferenceError || error || "An error occurred."
					}
				})

				if(response && response.successful){
					//view.setBusy(false)
					setNotBusy()
					this.getRouter().navTo("SignUpConfirmation")
				}else {
					Toast.show(response.message)
					$( ".sapMMessageToast" ).addClass( "sapMMessageToastDanger " );
				}
			}
			//view.setBusy(false)
			setNotBusy()
		},
		onInit: function() {

            var oRouter = this.getRouter();
			oRouter.getRoute("signup").attachMatched(this._onRouteMatched, this);
			this._oninit()
		},
		_onRouteMatched : async function (oEvent) {
			var vModel = new JSONModel({
				firstName : "",
				lastName : "",
				email : "",
				tel : "",
				username : "",
				tandc : false,
				validPw : false
			})
			thisView = this.getView()
			thisView.setModel(vModel)
			thisView.byId('submit').setEnabled(false)
			if(!thisView.byId('infoIcon').hasStyleClass('size1'))
				thisView.byId('infoIcon').addStyleClass('size1')
		}
	});
}, /* bExport= */ true);
