sap.ui.define([
    "univar/tcard/controller/BaseController",
    "sap/ui/model/json/JSONModel",
    "sap/ui/core/mvc/XMLView",
    "univar/tcard/img/Logo.png"
], function(BaseController, JSONModel, XMLView, Logo) {
	"use strict";

  var Page = sap.ui.requireSync("sap/m/Page")

	return BaseController.extend("univar.tcard.controller.Home", {

    

		onInit: function() {
      this._oninit();
            // var view = this.getView()

            // sap.ui.require([
            //     "../view/Home.view.xml"
            // ],
            // ()=>{
            //     XMLView.create({
            //         async:true,
            //         viewName: "./view/Home.view.xml"
            //     }).then(homeView=>{
            //         view.byId("page").addContent(homeView)
            //     })
            // })
            


            var oRouter = this.getRouter();
            oRouter.getRoute("home").attachMatched(this._onRouteMatched, this);
        },
        _onRouteMatched : function(oEvent){
          this.getView().setModel(new JSONModel({logouri:`./${Logo.default}`}))
              var controller = this;
              controller.isLoggedIn().then(function(logged){
                setTimeout(() => {
                if(logged){
                  controller.navigate("companies");
                } else{
                  controller.navigate("login");
                }
                },2000)
              })
             }        
      });
});
