sap.ui.define([
  "univar/tcard/controller/BaseController",
  'sap/ui/core/Fragment',
  "sap/m/MessageBox",
  "sap/ui/core/routing/History",
  "sap/ui/model/json/JSONModel",
  'sap/m/MessageToast',
  "univar/tcard/src/api-comm/oData/get-odata-service",
  "sap/ui/model/Filter",
  "sap/ui/model/FilterOperator",
  "sap/ui/model/FilterType"
], function (BaseController, Fragment, MessageBox, History, JSONModel, Toast, oDataService, Filter, Operator, FilterType) {
  "use strict";

  return BaseController.extend("univar.tcard.controller.Admin.order-admin-split", {
    onItemPress: function (oEvent) {

      var oItem = oEvent.getParameters().listItem;
      var oCtx = oItem.getBindingContext();
      var path = oCtx.getPath();
      this.getView().byId("detail").bindElement({
        path,
        parameters: {
          $expand: "OrderItems"
        }
      });
     
      //oCtx.getBinding().attachPatchCompleted(this._itemChanged)
    },
    search : function(oEvent){
      try{
          var title = null
          var query = oEvent.getParameter('query')
          
          var orderSearch = this.getView().getModel("order_search")
          var searchFilter = null;
          var filter = []

          if(query){
            title = 'Order: ID (*' + query + '*)'
            searchFilter = new Filter({path:"SearchField", operator: Operator.Contains, value1: query})
            orderSearch.setProperty("/searchFilter", searchFilter)
            filter.push(searchFilter)
          }
          else {
            title = 'Orders'
            orderSearch.setProperty("/searchFilter", undefined)
          }
          
          
          
          var statusFilter = orderSearch.getProperty("/statusFilter")
          if (statusFilter) {
            title += ', Status: (' + statusFilter.titleName + ')'
            filter.push(statusFilter)
          }

          var masterPage = this.getView().byId("master")
          masterPage._headerTitle.setProperty("text", title)

          var logTable = this.getView().byId('order-master-list')
          logTable.getBinding("items").filter(filter, FilterType.Application);
      }catch(err){
          this.showToast("An unexpected error has occured", "Danger")
          console.log(err)
      }
    },
    filterChanged : function(oEvent){
      try{
        var title = null
        var orderSearch = this.getView().getModel("order_search")
        var query = oEvent.getParameter('selectedItem')
        var statusFilter = null;
          
        var filter = []
          
        var searchFilter = orderSearch.getProperty("/searchFilter")
        if (searchFilter) {
          title = 'Order: ID (' + searchFilter.oValue1 + ')'
          filter.push(searchFilter)
        }
        else {
          title = 'Orders'
        }

        if(query){
            var key = query.getProperty("key")
            var text = query.getProperty('text')

            statusFilter = new Filter({path:"StatusId", operator: Operator.EQ, value1: key})
            statusFilter.titleName = text

            filter.push(statusFilter)

            orderSearch.setProperty("/statusFilter", statusFilter)
            title += ', Status (' + text +')'
        }
        else {
          orderSearch.setProperty("/statusFilter", statusFilter)
        }
          

          
          var masterPage = this.getView().byId("master")
          masterPage._headerTitle.setProperty("text", title)

          var logTable = this.getView().byId('order-master-list')
          logTable.getBinding("items").filter(filter, FilterType.Application);
      }catch(err){
          this.showToast("An unexpected error has occured", "Danger")
          console.log(err)
      }
    },
    delayRefresh : function(oEvent){
      setTimeout(()=>{
        this.getView().getModel().refresh()
      }, 1000)
    },
    onInit: function () {
      var oRouter = this.getRouter();
      oRouter.getRoute("orders").attachMatched(this.onRouteMatched, this);
    },
    onUpdateFinished: function (oEvent) {
      var oList = oEvent.getSource();
      var aItems = oList.getItems();
      if (aItems && aItems.length) {
        var oItem = aItems[0]
        oList.setSelectedItem(oItem)
        var oCtx = oItem.getBindingContext();
        var path = oCtx.getPath();
        this.getView().byId("detail").bindElement({path,
          parameters: {
            $expand: "OrderItems"
          }});
          //oCtx.getBinding().attachPatchCompleted(this._itemChanged)
      }
    },
    onRouteMatched: function (oEvent) {

      var view = this.getView()
      if(!view.hasStyleClass('spread-title')) view.addStyleClass('spread-title')
      this.isLoggedIn().then(result => {

        if (result) {
          oDataService.then(oModel => {

            //oModel.attachRequestFailed(this._requestFailed)

            var model = new sap.ui.model.json.JSONModel();

            model.oData.text = model.oData.text || "";
            this.getView().setModel(model, 'order_search');
            this.getView().setModel(oModel);

            oModel.getMetaModel().requestObject("/Orders/").then(res => {
              var oLastModified = oModel.getMetaModel().getLastModified();
              console.log("Last-Modified: " + oLastModified,
                oLastModified && oLastModified.toISOString(),
                "univar.controllers.OrderAdmin.order-admin-master");

            }).catch(err => console.log(err))

          })
        }else{
            this.navigate('login')
            this.showToast('login error','Danger')
        }
    }).catch(err=>{
        console.log(err)
        this.showToast(err,'Danger')
    })
    },
    _itemChanged : function(oEvent){
      debugger
    },
    _requestFailed : function(oEvent){
      debugger
    }
  })
})