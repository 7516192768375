sap.ui.define([
    "../exceptions/UnauthorisedException",
    "../security/authenticate",
], function(UnauthException, Authentication) {
    return {
        handleAsync : async function(itemId, customerId){
            

            var token = await Authentication.getToken()
            var response = await fetch(`${appconfig.host}/items/${customerId}/${itemId}/orders`,{
                method: 'GET',
                mode: "cors",
                headers:{
                    "Authorization":`Bearer ${token.accessToken}`
                }
            })         
            .catch((error)=>{
                console.log(error)
                throw new UnauthException("Fetch Failed", error)
            })

            if(response.status===200){
                return await response.json();
            }            
        }
    }
})