sap.ui.define(
    [
      "./delete-pricelist",
      "./create-pricelist",
      "./get-pricelist",
      "./update-pricelist"
    ],
    function(d,a,g,n) {
      return {
        delete: d  ,
        add: a  ,
        get: g  ,
        new: n
      };
    }
  );
  