/* global moment:true */
/* global sha256:true */

const cookieName = 'oauth-token'
const base64Client = 'Basic NjQ4ZjczZjYtYzI2NC00YWE0LThkZmYtZDQ5OWE0YTRjNzhlOjNmYTBmMzlmLThlM2ItNDUzOS1hNWQ2LTUyMzg0YjY2ZTAxOQ=='

const bufferMillis = 5000

const i = '648f73f6-c264-4aa4-8dff-d499a4a4c78e';
const s = '3fa0f39f-8e3b-4539-a5d6-52384b66e019';


sap.ui.define([
	"sap/ui/util/Storage",
    "../exceptions/UnauthorisedException",
    "../exceptions/BadRequestException",
    "moment",
    "sha256"
], function(Storage, UnauthException, BadRequestException,moment,sha256) {
    return {
        oStorage : new Storage(Storage.Type.session, 'login'),
        getToken : async function(){
            var token = this.oStorage.get(cookieName);
            if(token){
                var t = JSON.parse(token);
                 var now = moment()
                 var expires = moment(t.expires)
                 if((expires - now) < bufferMillis){
                     await this.login({refresh:t.refreshToken}).catch((error)=>{
                        throw new UnauthException("Login Failed", error)
                     })
                     token = this.oStorage.get(cookieName);
                     if(token){
                        var t = JSON.parse(token);
                     }
                 }
                 return t
            }
            return null;
        },
        setToken : function(accessToken, expires, refreshToken){
            var cookieVal = {accessToken, expires, refreshToken}
            var cookie = JSON.stringify(cookieVal)
            this.oStorage.put(cookieName,cookie);
        },
        logout : async function(){
            var token = this.oStorage.get(cookieName)
            if(token){
                this.oStorage.remove(cookieName);
                var t = JSON.parse(token);
                var response = await fetch(`${appconfig.host}/logout`,{
                    method: 'GET',
                    mode: "cors",
                    headers:{
                        "Authorization":`Bearer ${t.accessToken}`
                    }
                })         
                .catch((error)=>{
                    console.log(error)
                })
            }
        },
        login : async function({u,p,refresh,reset}){
           
            var grant = ""
            if(u && p){
                var h = sha256(p)
                
                grant = `grant_type=password&username=${u}&password=${h}`
            }else if (refresh){
                grant = `grant_type=refresh_token&refresh_token=${refresh}`
            }
            else if(reset){
                grant = `grant_type=password_reset&reset_ticket=${reset}`
            }
            else{
                throw new UnauthException('No Credentials Supplied.')
            }

            //grant = 'grant_type=password_reset&reset_key=12345678'
            
            var h = {
                "Authorization":`Basic ${btoa(`${i}:${s}`)}`,
                "content-type":"application/x-www-form-urlencoded"
            }

            var response = await fetch(`${appconfig.host}/login`,{
                method: 'POST',
                mode: "cors",
                headers:h,
                body:grant
            })         
            .catch((error)=>{
                console.log(error)
                this.logout()
                throw new UnauthException("Login Failed", error)
            })

            //console.log(response);

            if(response.status===200){

                var result = await response.json();

                var now = moment();
                var issued = moment(result.issued)
                
                var offset = now-issued;
                
                var expires = moment(result.expires).add(offset,'ms');

                this.setToken(result.access_token, expires._d, result.refresh_token)
            }else{
                var result = await response.json();
                this.logout()
                throw new BadRequestException(result)
            }
            return true;
        }
    }
})
