sap.ui.define([
    "../exceptions/UnauthorisedException",
    "../security/authenticate"
], function(UnauthException, Authentication) {
    return {
        handleGetAsync : async function(customerId){
            
            var token = await Authentication.getToken()
            var response = await fetch(`${appconfig.host}/customers/${customerId}/customercupboard`,{
                method: 'GET',
                mode: "cors",
                headers:{
                    "Authorization":`Bearer ${token.accessToken}`
                }
            })
            .catch((error)=>{
                console.log(error)
                throw new UnauthException("Fetch Failed", error)
            })

            if(response.status===200){
                return await response.json();
            }            
        },
        handlePostAsync : async function(customerId, priceListItemtemIds){
            
            var items = []
            if(Array.isArray(priceListItemtemIds)){
                items=priceListItemtemIds
            }else{
                items.push(priceListItemtemIds)
            }

            var token = await Authentication.getToken()
            var response = await fetch(`${appconfig.host}/customers/${customerId}/customercupboard`,{
                method: 'POST',
                mode: "cors",
                headers:{
                    "Authorization":`Bearer ${token.accessToken}`,
                    "Content-Type":"application/json"
                },
                body: JSON.stringify(
                    items
                )
            })
            .catch((error)=>{
                console.log(error)
                throw new UnauthException("Fetch Failed", error)
            })

            if(response.status===200){
                return {success:true, new: false}
            }  
            if(response.status===201){
                return {success:true, new: true}
            }  
            return {success: false}
        },
        handleDeleteAsync : async function(customerId, priceListItemtemIds){
            
            var items = []
            if(Array.isArray(priceListItemtemIds)){
                items=priceListItemtemIds
            }else{
                items.push(priceListItemtemIds)
            }

            var token = await Authentication.getToken()
            var response = await fetch(`${appconfig.host}/customers/${customerId}/customercupboard`,{
                method: 'DELETE',
                mode: "cors",
                headers:{
                    "Authorization":`Bearer ${token.accessToken}`,
                    "Content-Type":"application/json"
                },
                body: JSON.stringify(
                    items
                )                
            })
            .catch((error)=>{
                console.log(error)
                throw new UnauthException("Fetch Failed", error)
            })

            if(response.status===200){
                return {success:true, new: false}
            }  
            return {success: false}
        },
        handlePatchAsync : async function(customerId, priceListItemtemIds){
            
            var items = []
            if(Array.isArray(priceListItemtemIds)){
                items=priceListItemtemIds
            }else{
                items.push(priceListItemtemIds)
            }

            var token = await Authentication.getToken()
            var response = await fetch(`${appconfig.host}/customers/${customerId}/customercupboard`,{
                method: 'PATCH',
                mode: "cors",
                headers:{
                    "Authorization":`Bearer ${token.accessToken}`,
                    "Content-Type":"application/json"
                },
                body: JSON.stringify(items)
            })
            .catch((error)=>{
                console.log(error)
                throw new UnauthException("Fetch Failed", error)
            })

            if(response.status===200){
                return {success:true, new: false}
            }  
            return {success: false}
        },
        handleGetPriceListAsync : async function(customerId){
            
            var token = await Authentication.getToken()
            var response = await fetch(`${appconfig.host}/customers/${customerId}/pricelist`,{
                method: 'GET',
                mode: "cors",
                headers:{
                    "Authorization":`Bearer ${token.accessToken}`,
                    "Content-Type":"application/json"
                }
            })
            .catch((error)=>{
                console.log(error)
                throw new UnauthException("Fetch Failed", error)
            })

            if(response.status===200){
                return  await response.json()
            }  
            return {success: false}
        }
    }
})