
      sap.ui.requireSync("sap/m/Page");
sap.ui.requireSync("sap/m/Image");
sap.ui.requireSync("sap/m/OverflowToolbar");
sap.ui.requireSync("sap/m/ToolbarSpacer");
sap.ui.requireSync("sap/m/Text");
sap.ui.requireSync("univar/tcard/controller/Home.controller");

      var parser = new DOMParser();
      var xml = parser.parseFromString("<mvc:View \r\n\t\txmlns:mvc=\"sap.ui.core.mvc\" \r\n\t\txmlns=\"sap.m\"\r\n\t\tcontrollerName=\"univar.tcard.controller.Home\" \r\n\t\tdisplayBlock=\"true\">\r\n\t\r\n\t<Page backgroundDesign=\"Solid\" showHeader=\"false\">\r\n\t\t<content>\r\n\t\t\t<Image\r\n\t\t\t\tsrc=\"{/logouri}\"  \r\n\t\t\t\t\twidth=\"100%\"/>\r\n\t\t</content>\r\n\t\t\r\n        <footer>\r\n\t\t\t<OverflowToolbar>\r\n\t\t\t\t<ToolbarSpacer/>\r\n                <Text text=\"{= 'Version ' + ${appconfig>/version} }\" />\r\n            </OverflowToolbar>\r\n        </footer>\r\n\t</Page>\r\n</mvc:View>\r\n", "text/xml");
      module.exports = xml;
    