sap.ui.define([
    "univar/tcard/controller/BaseController",
	"sap/m/MessageBox",
	"sap/ui/core/routing/History"
], function(BaseController, MessageBox, History) {
	"use strict";

	return BaseController.extend("univar.tcard.controller.UserDetails", {
		handleRouteMatched: function(oEvent) {

            this.isLoggedIn().then(result=>{

                if (result){
				}else{					
                    this.navigate('Login')
				}
			}).catch(err=>{
				console.log(err)
				this.navigate('Login')
			})
		},
		_onButtonPress: function() {
			var oHistory = History.getInstance();
			var sPreviousHash = oHistory.getPreviousHash();
			var oQueryParams = this.getQueryParameters(window.location);

			if (sPreviousHash !== undefined || oQueryParams.navBackToLaunchpad) {
				window.history.go(-1);
			} else {
				var oRouter = sap.ui.core.UIComponent.getRouterFor(this);
				oRouter.navTo("default", true);
			}

		},
		getQueryParameters: function(oLocation) {
			var oQuery = {};
			var aParams = oLocation.search.substring(1).split("&");
			for (var i = 0; i < aParams.length; i++) {
				var aPair = aParams[i].split("=");
				oQuery[aPair[0]] = decodeURIComponent(aPair[1]);
			}
			return oQuery;

		},
		onInit: function() {
			this.oRouter = sap.ui.core.UIComponent.getRouterFor(this);
			this.oRouter.getTarget("profile").attachDisplay(jQuery.proxy(this.handleRouteMatched, this));
			this._oninit()
		}
	});
}, /* bExport= */ true);
