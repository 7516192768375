sap.ui.define([
    "sap/ui/core/mvc/Controller",
	"sap/ui/core/UIComponent",
	"sap/ui/model/json/JSONModel",
	'sap/m/MessageToast',
	'sap/ui/core/Fragment',
	'async-mutex',
	'univar/tcard/src/security/authenticate',
	"univar/tcard/src/security/password-strength-check",
	"univar/tcard/src/api-comm/get-my-details",
	"univar/tcard/src/api-comm/post-update-password",
	'sap/ui/core/ws/WebSocket',
	'moment'
], function(Controller, UIComponent, JSONModel, Toast, Fragment, AsyncMutex, Authentication, CheckAsync, MyDetails, ResetPassword, WebSocket,moment) {
	"use strict";

	var mutex = new AsyncMutex.Mutex();
	var Check
	var Severity = {
		Warning: "Warning",
		Success: "Success",
		Danger: "Danger"
	}

	return Controller.extend("univar.tcard.controller.BaseController", {

		getRouter : function () {
			return UIComponent.getRouterFor(this);
		},

		isLoggedIn: async function(){
			
			if(!sap.ui.getCore().controller){
				sap.ui.getCore().controller=this;
			}
			var release = await mutex.acquire()
			try{
				var isLoggedIn = !! await Authentication.getToken()
				.catch((err)=>{
					isLoggedIn = false;
				})
				var controller = sap.ui.getCore().controller;

				var model = sap.ui.getCore().getModel()
				if(!model){
					model = new sap.ui.model.json.JSONModel();
					sap.ui.getCore().setModel(model)
				}
				var userModel = model.oData.user || {}
				if(!!(userModel.isLoggedIn)===isLoggedIn){
					return isLoggedIn;
				}
				userModel.isLoggedIn = isLoggedIn
				// var uShell = sap.ui.getCore().byId('rootShell')

				// uShell.destroyUser();
				// uShell.destroyHeadItems();
				// uShell.destroyHeadEndItems();

				if(isLoggedIn){
					var user = await MyDetails.handleAsync()
						.catch((err)=>{
							console.log(err)
							throw {Error:err}
						});
					if(!user){
						Authentication.logout()
						return false;
					}
					var permissions = user.Permissions
					userModel.Permissions = {}
					permissions.forEach(p=>user.Permissions[p]=true)
					var anyAdmin = !!(permissions && permissions.length)
					userModel.anyAdmin = anyAdmin

					// var menu = controller.getView().byId('shellMenu')
					// console.log(menu)


					var menuItems = [];
					menuItems.push({
							text:"Logout",
							startsSection:true,
							onPress : this.logout
						})
					menuItems.push(
						{
							text:"Profile",
							onPress: this.profileClicked
						})
					if(anyAdmin){
						menuItems.push({
							text:"System Logs",
							startsSection:true,
							onPress: this.navToSysLogs
						})
						menuItems.push({
							text:"Order Admin",
							onPress: this.navToOrders
						})
						
						menuItems.push({
							text:"Contacts Admin",
							onPress: this.navToContacts
						})
						menuItems.push({
							text:"Customer Admin",
							onPress: this.navToCustomers
						})
						menuItems.push({
							text:"Items Admin",
							onPress: this.navToItems
						})
						menuItems.push({
							text:"Password Policy",
							onPress: this.navToPolicy
						})
					}

					var appContext = sap.ui.getCore().AppContext;

					appContext.setMenuItems(menuItems)
					
					// var rootUser = 	new User('rootUser',{username : user.FullName, image:"sap-icon://person-placeholder", press: controller.profileClicked})
					// uShell.setUser(rootUser)
					// var logoff = new ShellHeadItem('logoff',{
					// 	tooltip:"Log Off",
					// 	icon:"sap-icon://log",
					// 	press : controller.logout
					// })
					// uShell.addHeadEndItem(logoff)
					// if(anyAdmin){
					// 	var menu = new ShellHeadItem('rootAdminMenu',{
					// 		tooltip:"Configuration",
					// 		icon:"sap-icon://menu2",
					// 		press:function(oEvent){
					// 			if(sap.ui.getCore().controller){
					// 				controller.handlePressOpenMenu(oEvent)
					// 			}
					// 		}
					// 	})
					// 	uShell.addHeadItem(menu)
					// }
					
					if(!this.socket){
						this.socket = new WebSocket(appconfig.host.replace('http','ws') + '/websocket/connect/'+user.Id)
						this.socket.logout = function(immediate){
							if(immediate){
								controller.logout()
								controller.showToast("Your account has been disabled.", Severity.Danger)
							}

						}
						this.socket.showToast = this.showToast

						this.socket.attachMessage(function(oControlEvent){
							if(oControlEvent){
								console.log(oControlEvent)
								var data = oControlEvent.getParameter("data")
								if(typeof data == 'string'){
									try{
										data = JSON.parse(data);
									}
									catch(e){
										console.log("unexpected msg", data)
										data=undefined
									}
								}
								if(data){
									console.log(data)
									var callback, parameters;
									if(data.hasOwnProperty("callback")){
										var callback = data.callback;
									}else{
										console.log("No callback from socket", data)
									}
									if(data.hasOwnProperty("parameters")){
										var parameters = data.parameters;
									}
									if(this.hasOwnProperty(callback) && typeof this[callback] == 'function'){
										this[callback](...parameters);
									}else if(controller.hasOwnProperty(callback) && typeof controller[callback] == 'function'){
										controller[callback](...parameters);
									}
								}
							}
						})
						
					}


				}
				this.getView().setModel(new sap.ui.model.json.JSONModel(userModel),'user')
				return isLoggedIn;
				
			}
			catch(e){
				console.log(e)
			}
			finally{
				release()
			}
		},
		profileClicked:async function(oEvent){
			if(sap.ui.getCore().controller._userProfile){
				sap.ui.getCore().controller._userProfile.setBusy(false)
				sap.ui.getCore().controller._userProfile.destroy()
			}
			var dialog = await Fragment.load({
				name:'univar.tcard.view.UserProfile',
				controller:sap.ui.getCore().controller
			})
			sap.ui.getCore().controller._userProfile = dialog
			sap.ui.getCore().controller.passwordView = dialog
			
			var model = sap.ui.getCore().getModel('profile')

			if(!model){
				var profile = await MyDetails.handleAsync();
				model = new sap.ui.model.json.JSONModel(profile);
				sap.ui.getCore().setModel(model, 'profile')
			}
			sap.ui.getCore().controller._userProfile.setModel(model)
			sap.ui.getCore().controller._userProfile.open()		
		},passLiveChange: function (oEvent){

			var ctrl = oEvent.getSource();
			var value = oEvent.getParameter('newValue')

			if(value.length > 30){
				ctrl.setValueState(sap.ui.core.ValueState.Error)
				ctrl.setValueStateText("Too Long (max 30)")
				return;
			}
			
			var model = this.passwordView.getModel()
			model.oData.pass = value

			var valid = Check.setPasswordStrength(ctrl,value)
			model.oData.valid = valid;			

			if(this.passwordView.confCtrl){

				model.oData.validPw = Check.checkPasswords(this.passwordView, this.passwordView.confCtrl)
			}
			this.passwordView.setModel(model)
		},
		confLiveChange: function (oEvent){
			var ctrl = oEvent.getSource();
			var value = oEvent.getParameter('newValue')
			
			this.passwordView.confCtrl = ctrl;

			var model = this.passwordView.getModel()
			model.oData.conf = value
						
			model.oData.validPw = Check.checkPasswords(this.passwordView, ctrl)
			this.passwordView.setModel(model)				
		},
		onUpdatePasswordDialogClose : async function(oEvent){
			var button = oEvent.getSource()
			var userProfile = sap.ui.getCore().controller._userProfile
			var setNotBusy=function(){}
			var close = function(){
				//userProfile.setBusy(false)
				setNotBusy()
				userProfile.destroy()
				userProfile = undefined
			}

			if(button.sId){
				//userProfile.setBusy(true)
				setNotBusy=this.getBusyStack().addBusy(userProfile)
				var model = userProfile.getModel();
				var item = model.oData

				if(button.sId==="ok")	
							{
					if(item.pass && item.conf && item.pass===item.conf && item.validPw){
						await ResetPassword.handleAsync(item.pass)
							.catch(err=>{
								console.log(err)
								this.showToast("Failed to update password.", Severity.Danger );
								//userProfile.setBusy(false)
								setNotBusy()
								return;
							});
						model.setProperty('pass','')
						model.setProperty('conf','')
						model.setProperty('validPw',false)
						this.showToast("Password updated.", Severity.Success );
						close()
						
					}
					else{
						this.showToast("New password does not meet the minimum requirements.", Severity.Warning );
						//userProfile.setBusy(false)
						setNotBusy()
					}
				}
				else{
					close()
				}
			}
		},
		logout: function(oEvent){
			Authentication.logout()
			if(!sap.ui.getCore().controller){
				sap.ui.getCore().controller=this;
			}
			if(sap.ui.getCore().controller.socket){
				sap.ui.getCore().controller.socket.close();
			}
			sap.ui.getCore().controller.getRouter().navTo("login", {}, true /*no history*/);
		},
		navigate: function(dest){
			this.getRouter().navTo(dest)
		},
		showToast : function(message, severity){
			Toast.show(message)
			
			if(severity){
				$( ".sapMMessageToast" ).addClass( 'sapMMessageToast'+severity+' ' ); 
			}
		},
		dateFormatter : function(text){
			var date = new Date(text)
			var asString = moment(date).format("DD/MM/YYYY")
			//console.log(date,asString)
			return asString;
		},
		dateTimeFormatter : function(text){
			var date = moment(new Date(text))
			var now  = moment(new Date())
			var asString = ''
			
			asString = date.fromNow(true) + ' ('

			if(now.diff(date, 'days')){
				// if(now.diff(date, 'weeks')){
				// 	if(now.diff(date, 'months')){
				// 		if(now.diff(date, 'years')){
				// 			asString = date.format("LL")
				// 		}
				// 	}
				// }else{
				// 	asString += 
				// }
				asString += ' ' + date.format("LL") 
			}
			asString += ' ' + date.format("LTS") + ' )'

			//console.log(date,asString)
			return asString;
		},
		numberFormatter:function(input){
			return parseInt(input.replace(/,/g,'')).toString()
		},
		_oninit: function(){
			this.getView().setModel(new JSONModel(appconfig), 'appconfig')
			sap.ui.getCore().controller=this;
			this.passwordView = this.getView()
			CheckAsync.then(check=>Check=check);
		}
		,
		getBusyStack : function(){
			return sap.ui.getCore().busyStack
		}
		,navToSysLogs : function(){sap.ui.getCore().controller.navigate('systemlogs')}
		,navToOrders: function(){sap.ui.getCore().controller.navigate('orders')}
		,navToContacts: function(){sap.ui.getCore().controller.navigate('contacts')}
		,navToCustomers: function(){sap.ui.getCore().controller.navigate('customers')}
		,navToItems: function(){sap.ui.getCore().controller.navigate('items')}
		,navToPolicy: function(){sap.ui.getCore().controller.navigate('passwordpolicy')}
	});
});
