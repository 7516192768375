sap.ui.requireSync("sap/ui/core/library");
sap.ui.requireSync("sap/ui/core/messagebundle.properties");
sap.ui.requireSync("sap/ui/core/messagebundle_en.properties");
sap.ui.requireSync("sap/ui/core/cldr/en.json");
sap.ui.requireSync("sap/m/library");
sap.ui.requireSync("sap/m/messagebundle.properties");
sap.ui.requireSync("sap/m/messagebundle_en.properties");
sap.ui.requireSync("sap/ui/core/cldr/en.json");
sap.ui.requireSync("sap/f/library");
sap.ui.requireSync("sap/f/messagebundle.properties");
sap.ui.requireSync("sap/f/messagebundle_en.properties");
sap.ui.requireSync("sap/ui/core/cldr/en.json");
sap.ui.requireSync("sap/ui/unified/library");
sap.ui.requireSync("sap/ui/unified/messagebundle.properties");
sap.ui.requireSync("sap/ui/unified/messagebundle_en.properties");
sap.ui.requireSync("sap/ui/core/cldr/en.json");
sap.ui.requireSync("sap/ui/core/mvc/XMLView");
sap.ui.requireSync("./view/App.view.xml");
sap.ui.requireSync("sap/ui/core/mvc/XMLView");
sap.ui.requireSync("./view/Companies/AddToBasket.fragment.xml");
sap.ui.requireSync("./view/Companies/QuantityWarning.fragment.xml");
sap.ui.requireSync("./view/Companies/editItem.fragment.xml");
sap.ui.requireSync("./view/usermenu.fragment.xml");
sap.ui.requireSync("./view/UserProfile.fragment.xml");
sap.ui.requireSync("./view/Admin/adminmenu.fragment.xml");
sap.ui.requireSync("./view/Admin/newUser.fragment.xml");
sap.ui.requireSync("sap/ui/core/mvc/XMLView");
sap.ui.requireSync("./view/Home.view.xml");
sap.ui.requireSync("./view/Login.view.xml");
sap.ui.requireSync("./view/ResetPassword.view.xml");
sap.ui.requireSync("./view/PasswordRecovery.view.xml");
sap.ui.requireSync("./view/ResetPassword.view.xml");
sap.ui.requireSync("./view/SignUp.view.xml");
sap.ui.requireSync("./view/SignUpConfirmation.view.xml");
sap.ui.requireSync("./view/UserDetails.view.xml");
sap.ui.requireSync("./view/Companies/company-selection.view.xml");
sap.ui.requireSync("./view/Companies/shoppinglist-split.view.xml");
sap.ui.requireSync("./view/Admin/contact-admin-split.view.xml");
sap.ui.requireSync("./view/Admin/customer-admin-split.view.xml");
sap.ui.requireSync("./view/Admin/item-admin-split.view.xml");
sap.ui.requireSync("./view/Admin/order-admin-split.view.xml");
sap.ui.requireSync("./view/Admin/pricelist-admin-split.view.xml");
sap.ui.requireSync("./view/Admin/uom-admin-split.view.xml");
sap.ui.requireSync("./view/Admin/PasswordPolicy.view.xml");
sap.ui.requireSync("./view/Admin/systemlogs.view.xml");
sap.ui.requireSync("sap/ui/model/resource/ResourceModel");
sap.ui.requireSync("./i18n/i18n.properties");
sap.ui.requireSync("./i18n/i18n_en.properties");
sap.ui.requireSync("sap/m/routing/Router");
module.exports={
	"_version":"1.12.0",
	"sap.app":{
	  "id":"univar.tcard",
	  "type":"application"
	},
	"extensions":{
	  "fragment":{
		"config":{
		  "fragmentType":"XML",
		  "fragmentPath":"univar.tcard.view"
		},
		"fragments":[
			{
			  "fragmentName":"AddToBasket",
			  "fragmentPath":"univar.tcard.view.Companies"
			},
			{
			  "fragmentName":"QuantityWarning",
			  "fragmentPath":"univar.tcard.view.Companies"
			},
		  {
			"fragmentName":"editItem",
			"fragmentPath":"univar.tcard.view.Companies"
		  },
		  {
			"fragmentName":"usermenu"
		  },
		  {
			"fragmentName":"UserProfile"
		  },
		  {
			"fragmentName":"adminmenu",
			"fragmentPath":"univar.tcard.view.Admin"
		  },
		  {
			"fragmentName":"newUser",
			"fragmentPath":"univar.tcard.view.Admin"
		  }
		]
	  }
	},
	"sap.ui5":{
	  "dependencies":{
		"minUI5Version":"1.60.0",
		"libs":{
		  "sap.ui.core":{
  
		  },
		  "sap.m":{
  
		  },
		  "sap.f":{
  
		  },
		  "sap.ui.unified":{
  
		  }
		}
	  },
	  "rootView":{
		"viewName":"univar.tcard.view.App",
		"type":"XML",
		"async":true,
		"id":"app"
	  },
	  "models":{
		"i18n":{
		  "type":"sap.ui.model.resource.ResourceModel",
		  "settings":{
			"bundleName":"univar.tcard.i18n.i18n"
		  }
		}
	  },
	  "resources":{
		"css":[
		  {
			"uri":"css/styles.css"
		  }
		]
	  },
	  "routing":{
		"config":{
		  "routerClass":"sap.m.routing.Router",
		  "viewType":"XML",
		  "viewPath":"univar.tcard.view",
		  "controlId":"appShell",
		  "clearTarget":true,
		  "controlAggregation":"pages",
		  "transition":"slide",
		  "bypassed":{
			"target":"notFound"
		  },
		  "async":true
		},
		"routes":[
		  {
			"pattern":"",
			"name":"home",
			"target":"home"
		  },
		  {
			"pattern":"Login",
			"name":"login",
			"target":"login"
		  },
		  {
			"pattern":"resetpassword",
			"name":"resetpassword",
			"target":"notfound"
		  },
		  {
			"pattern":"passwordrecovery",
			"name":"passwordrecovery",
			"target":"passwordrecovery"
		  },
		  {
			"pattern":"resetpassword/{token}",
			"name":"resetpassword",
			"target":"resetpassword"
		  },
		  {
			"pattern":"signup",
			"name":"signup",
			"target":"signup"
		  },
		  {
			"pattern":"SignUpConfirmation",
			"name":"SignUpConfirmation",
			"target":"SignUpConfirmation"
		  },
		  {
			"pattern":"profile",
			"name":"profile",
			"target":"profile"
		  },
		  {
			"pattern":"companies",
			"name":"companies",
			"target":"companies"
		  },
		  {
			"pattern":"companies/{companyId}",
			"name":"company",
			"target":"company"
		  },
		  {
			"pattern":"contacts",
			"name":"contacts",
			"target":"contacts"
		  },
		  {
			"pattern":"customers",
			"name":"customers",
			"target":"customers"
		  },
		  {
			"pattern":"items",
			"name":"items",
			"target":"items"
		  },
		  {
			"pattern":"orders",
			"name":"orders",
			"target":"orders"
		  },
		  {
			"pattern":"pricelists",
			"name":"pricelists",
			"target":"pricelists"
		  },
		  {
			"pattern":"unitsofmeasure",
			"name":"unitsofmeasure",
			"target":"unitsofmeasure"
		  },
		  {
			"pattern":"passwordpolicy",
			"name":"passwordpolicy",
			"target":"passwordpolicy"
		  },
		  {
			"pattern":"systemlogs",
			"name":"systemlogs",
			"target":"systemlogs"
		  }
		],
		"targets":{
		  "home":{
			"viewName":"Home",
			"viewId":"home",
			"viewLevel":1
		  },
		  "login":{
			"viewName":"Login",
			"viewId":"login",
			"viewLevel":2
		  },
		  "passwordrecovery":{
			"viewName":"PasswordRecovery",
			"viewId":"passwordRecovery",
			"viewLevel":3
		  },
		  "resetpassword":{
			"viewName":"ResetPassword",
			"viewId":"resetpassword",
			"viewLevel":2
		  },
		  "notFound":{
			"viewId":"notFound",
			"viewName":"NotFound",
			"transition":"show"
		  },
		  "signup":{
			"viewName":"SignUp",
			"viewId":"signup",
			"viewLevel":2
		  },
		  "SignUpConfirmation":{
			"viewName":"SignUpConfirmation",
			"viewId":"SignUpConfirmation",
			"viewLevel":3
		  },
		  "profile":{
			"viewName":"UserDetails",
			"viewId":"UserDetails",
			"viewLevel":3
		  },
		  "companies":{
			"viewName":"company-selection",
			"viewId":"company-selection",
			"viewPath":"univar.tcard.view.Companies",
			"viewLevel":3
		  },
		  "company":{
			"viewName":"Companies.shoppinglist-split",
			"viewId":"shoppinglist-split",
			"viewLevel":4
		  },
		  "systemlogs":{
			"viewName":"systemlogs",
			"viewId":"systemlogs",
			"viewPath":"univar.tcard.view.Admin",
			"viewLevel":3
		  },
		  "adminuomMenuItem":{
			"viewName":"adminuomMenuItem",
			"viewId":"adminuomMenuItem",
			"viewPath":"univar.tcard.view.Admin",
			"viewLevel":3
		  },
		  "admincustomersMenuItem":{
			"viewName":"admincustomersMenuItem",
			"viewId":"admincustomersMenuItem",
			"viewPath":"univar.tcard.view.Admin",
			"viewLevel":3
		  },
		  "orders":{
			"viewName":"order-admin-split",
			"viewId":"order-admin-split",
			"viewPath":"univar.tcard.view.Admin",
			"viewLevel":3
		  },
		  "items":{
			"viewName":"item-admin-split",
			"viewId":"item-admin-split",
			"viewPath":"univar.tcard.view.Admin",
			"viewLevel":3
		  },
		  "contacts":{
			"viewName":"contact-admin-split",
			"viewId":"contact-admin-split",
			"viewPath":"univar.tcard.view.Admin",
			"viewLevel":3
		  },
		  "passwordpolicy":{
			"viewName":"PasswordPolicy",
			"viewId":"passwordpolicy",
			"viewPath":"univar.tcard.view.Admin",
			"viewLevel":3
		  },
		  "customers":{
			"viewName":"customer-admin-split",
			"viewId":"customer-admin-split",
			"viewPath":"univar.tcard.view.Admin",
			"viewLevel":3
		  },
		  "pricelists":{
			"viewName":"pricelist-admin-split",
			"viewId":"pricelist-admin-split",
			"viewPath":"univar.tcard.view.Admin",
			"viewLevel":3
		  },
		  "unitsofmeasure":{
			"viewName":"uom-admin-split",
			"viewId":"uom-admin-split",
			"viewPath":"univar.tcard.view.Admin",
			"viewLevel":3
		  }
		}
	  }
	}
  }