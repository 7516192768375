sap.ui.define([
    'sap/ui/model/odata/v4/ODataModel'
], async function(ODataModel) {
    //var token = await Authentication.getToken()
    return new ODataModel({
        serviceUrl: `${appconfig.proxy}${appconfig.host}/odata/`,
        synchronizationMode : "None",
        operationMode : "Server",
        groupId : '$direct',
        httpHeaders: {
            "withCredentials":"true",
            "crossDomain":"true"
        }
        // httpHeaders:{
        //     "Authorization":`Bearer ${token.accessToken}`,
        //     "content-type":"application/x-www-form-urlencoded"
        // }
    })
})